import React from 'react';

import { IMAGES } from '../../enum/index.jsx';
import Alert from './index.js';

const WarningAlert = props => {
  return (
    <Alert
      type="warning"
      icon={<img width="24px" src={IMAGES.ALERT_WARNING_TRIANGLE_ICON} alt="pending" />}
      {...props}
    />
  );
};

export default WarningAlert;
