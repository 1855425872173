import { PlusOutlined } from '@ant-design/icons';
import { Col, Empty, Row } from 'antd';
import { filter, flatten, forEach, get, map, merge, omit, set, sortBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { handleApiErrors } from '../../../api/axiosInstance';
import { RATES_API } from '../../../api/rates';
import StripeAPI from '../../../api/stripe';
import { Button, ConfirmModal } from '../../../components';
import FormItem from '../../../components/Form/FormItem';
import SpaceSpinner from '../../../components/SpaceSpinner';
import Spinner from '../../../components/Spinner';
import DynamicCustomFieldFormItems from '../../../components/Table/DynamicCustomFieldFormItems/DynamicNameValueFormItems';
import Text from '../../../components/Text';
import NumericInput from '../../../components/TextInput/NumericInput';
import Toast from '../../../components/Toast';
import Transfer from '../../../components/Transfer';
import useModal from '../../../hooks/useModal';
import { hasSystemAdminRole } from '../../../utils/roles';
import { selectStoreCurrentCompany, useStoreSelector } from '../../../utils/storeSelectors';
import FeatureFormItem from './FeatureFormItem';
import SettingSectionWrapper from './SettingSectionWrapper';

const initialCustomFields = [{ id: +new Date(), name: '', value: '' }];

const StripeInvoiceSettingsFormItems = props => {
  const {
    t,
    authUser,
    stripeCustomerId,
    companySettings,
    companySettingValues,
    handleSettingValueChange,
    onFeatureChange,
    disabled,
  } = props;

  const currentCompany = useStoreSelector(selectStoreCurrentCompany);
  const [stripeCustomFields, setStripeCustomFields] = useState([...initialCustomFields]);
  const [stripeCompanySettings, setStripeCompanySettings] = useState();

  const [
    isConfirmDupInvoiceSettingRemovalOpen,
    openConfirmDupInvoiceSettingRemoval,
    closeConfirmDupInvoiceSettingRemoval,
    confirmDupInvoiceSettingRemovalProps,
  ] = useModal();

  useEffect(() => {
    if (!stripeCompanySettings && companySettingValues.stripe) {
      const setting = companySettingValues?.stripe || {};

      const duplicateInvoices = get(setting, 'customFields.duplicateInvoices');
      if (duplicateInvoices) {
        forEach(duplicateInvoices, (invoice, i) => {
          invoice.id = +new Date() + i;
        });
        set(setting, 'customFields.duplicateInvoices', duplicateInvoices);
      }

      setStripeCompanySettings(setting);
    }
  }, [companySettingValues, stripeCompanySettings]);

  const addIdsToCustomFields = useCallback(fields => {
    return fields.map((field, i) => {
      field.id = +new Date() + i;
      return field;
    });
  }, []);

  const customFieldsQuery = useQuery({
    queryKey: ['fetchCustomerInformation', stripeCustomerId],
    enabled: !!stripeCustomerId && hasSystemAdminRole(authUser),
    queryFn: () => new StripeAPI().fetchCustomerInformation(stripeCustomerId),
    onError: error => handleApiErrors(error.response),
    onSuccess: data => {
      const fields = get(data, 'customInvoiceFields') || [];

      if (!fields.length) {
        initialCustomFields[0].id = +new Date();
        setStripeCustomFields([...initialCustomFields]);
      } else {
        setStripeCustomFields(addIdsToCustomFields(fields));
      }
    },
  });

  const saveCustomFieldsMutation = useMutation(
    () => {
      const filteredFields = stripeCustomFields.filter(item => !!item.name && !!item.value);
      return new StripeAPI().updateStripeCustomFields(stripeCustomerId, {
        invoiceSettings: {
          customFields: !filteredFields.length
            ? ''
            : filteredFields.map(item => omit(item, ['id'])),
        },
      });
    },
    {
      onError: error => handleApiErrors(error.response),
      onSuccess: data => {
        const fields = get(data, 'customInvoiceFields', []);

        if (!fields.length) {
          initialCustomFields[0].id = +new Date();
          setStripeCustomFields([...initialCustomFields]);
        } else {
          setStripeCustomFields(addIdsToCustomFields(fields));
        }
      },
    },
  );

  const handleStripeValuesChange = useCallback(
    settings => {
      const stripeSettings = {
        ...merge({}, stripeCompanySettings, settings),
      };
      handleSettingValueChange({ stripe: stripeSettings });
      setStripeCompanySettings(stripeSettings);
    },
    [handleSettingValueChange, stripeCompanySettings],
  );

  const handleStripeDefaultInvoiceChange = useCallback(
    settings => {
      const stripeSettings = {
        ...stripeCompanySettings,
        customFields: {
          ...get(stripeCompanySettings, 'customFields', {}),
          defaultInvoice: {
            ...get(stripeCompanySettings, 'customFields.defaultInvoice', {}),
            ...settings,
          },
        },
      };
      handleSettingValueChange({ stripe: stripeSettings });
      setStripeCompanySettings(stripeSettings);
    },
    [handleSettingValueChange, stripeCompanySettings],
  );

  const handleStripeDuplicateInvoiceChange = useCallback(
    (i, settings) => {
      const updatedInvoices = get(stripeCompanySettings, 'customFields.duplicateInvoices', []);

      updatedInvoices[i] = {
        ...(updatedInvoices[i] || {}),
        ...settings,
      };

      const stripeSettings = {
        ...stripeCompanySettings,
        customFields: {
          ...get(stripeCompanySettings, 'customFields', {}),
          duplicateInvoices: [...updatedInvoices],
        },
      };

      handleSettingValueChange({
        stripe: omit(stripeSettings, 'customFields.duplicateInvoices[*].id'),
      });
      setStripeCompanySettings(stripeSettings);
    },
    [handleSettingValueChange, stripeCompanySettings],
  );

  const removeStripeDuplicateInvoice = useCallback(
    index => {
      const duplicateInvoices = get(stripeCompanySettings, 'customFields.duplicateInvoices', []);
      duplicateInvoices.splice(index, 1);

      const stripeSettings = {
        ...stripeCompanySettings,
        customFields: {
          ...get(stripeCompanySettings, 'customFields', {}),
          duplicateInvoices,
        },
      };

      handleSettingValueChange({ stripe: stripeSettings });
      setStripeCompanySettings(stripeSettings);
    },
    [handleSettingValueChange, stripeCompanySettings],
  );

  const formatPlanOptions = useCallback(({ documents }) => {
    if (!Array.isArray(documents)) return [];

    return sortBy(
      documents.map(plan => ({
        value: plan._id,
        label: plan.name,
      })),
      'label',
    );
  }, []);

  const ratesQuery = useQuery({
    select: formatPlanOptions,
    placeholderData: { documents: [], totalCount: 7 },
    enabled: !!companySettings.duplicateInvoice,
    queryKey: ['fetchCompanyRates', currentCompany._id],
    queryFn: () => RATES_API.fetchCompanyRates(currentCompany._id),
    onError: error => {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('loadRatesError'),
        });
      });
    },
  });

  const unasignedRates = useMemo(() => {
    const rates = [];

    if (Array.isArray(ratesQuery.data) && stripeCompanySettings) {
      const rateIds = ratesQuery.data.map(rate => rate.value);

      const defaultInvoiceProducts = get(
        stripeCompanySettings,
        'customFields.defaultInvoice.productsIds',
        [],
      );
      const duplicateInvoices = get(stripeCompanySettings, 'customFields.duplicateInvoices', []);
      const duplicateInvoiceProducts = flatten(
        map(duplicateInvoices, invoice => get(invoice, 'productsIds', [])),
      );

      return filter(rateIds, id => {
        return !defaultInvoiceProducts.includes(id) && !duplicateInvoiceProducts.includes(id);
      });
    }
    return rates;
  }, [ratesQuery.data, stripeCompanySettings]);

  if (!hasSystemAdminRole(authUser)) return null;
  if (customFieldsQuery.isFetching) return <SpaceSpinner />;

  return (
    <SettingSectionWrapper title={t('stripeInvoiceSettings')} md={24} lg={24}>
      <FeatureFormItem
        label={t('enableDuplicateInvoice?')}
        value={companySettings.duplicateInvoice}
        onChange={checked => onFeatureChange({ duplicateInvoice: checked })}
        disabled={disabled}
      />

      <FormItem label={t('numberOfDaysUntilDue')} style={{ maxWidth: 180 }}>
        <NumericInput
          value={get(stripeCompanySettings, 'invoiceDaysUntilDue')}
          onChange={days => handleStripeValuesChange({ invoiceDaysUntilDue: parseInt(days) })}
          onKeyPress={event => {
            if ([',', '.'].includes(event.key)) {
              event.preventDefault();
            }
          }}
        />
      </FormItem>

      <Row>
        <Col xs={24} md={12}>
          <FormItem label={t('customFields')} hidden={!stripeCustomerId}>
            <DynamicCustomFieldFormItems
              t={t}
              extra={t('fieldsWithoutNameAndValueWillBeRemovedAfterSave')}
              disabled={disabled || saveCustomFieldsMutation.isLoading}
              dataSource={stripeCustomFields}
              onChange={fields => setStripeCustomFields(fields)}
              extraAction={{
                label: t('saveFields'),
                callback: saveCustomFieldsMutation.mutateAsync,
              }}
            />
          </FormItem>
        </Col>
      </Row>

      {!!companySettings.duplicateInvoice && (
        <section>
          <Text renderAs="p" variant="b" size="sm">
            {t('defaultInvoiceSettings')}
          </Text>

          <Row>
            <Col xs={24} md={12}>
              <FormItem label={t('customFields')} hidden={!stripeCustomerId}>
                <DynamicCustomFieldFormItems
                  t={t}
                  maxItems={4}
                  disabled={disabled}
                  dataSource={get(stripeCompanySettings, 'customFields.defaultInvoice.fields')}
                  onChange={fields => handleStripeDefaultInvoiceChange({ fields })}
                />
              </FormItem>
            </Col>
          </Row>

          <FormItem>
            <Spinner spinning={ratesQuery.isFetching}>
              <Transfer
                t={t}
                disabled={disabled}
                dataSource={get(ratesQuery, 'data', [])}
                leftListTitle={t('unassignedProducts')}
                selectableValues={unasignedRates}
                rightListTitle={t('productsAssociatedToInvoice')}
                initialSelectedItems={get(
                  stripeCompanySettings,
                  'customFields.defaultInvoice.productsIds',
                  [],
                )}
                onChange={productsIds => {
                  handleStripeDefaultInvoiceChange({ productsIds });
                }}
              />
            </Spinner>
          </FormItem>
        </section>
      )}

      {!!companySettings.duplicateInvoice && (
        <section>
          <Row align="middle" justify="space-between" style={{ marginBottom: 16 }}>
            <Col flex={1}>
              <Text renderAs="p" variant="b" size="sm">
                {t('duplicateInvoiceSettings')}
              </Text>
            </Col>
            {get(stripeCompanySettings, 'customFields.duplicateInvoices', []).length && (
              <Col>
                <Button
                  size="xs"
                  icon={<PlusOutlined />}
                  onClick={() =>
                    handleStripeDuplicateInvoiceChange(
                      [get(stripeCompanySettings, 'customFields.duplicateInvoices', []).length],
                      {
                        id: +new Date(),
                        fields: [],
                        productIds: [],
                      },
                    )
                  }
                >
                  {t('addDuplicateInvoiceSetting')}
                </Button>
              </Col>
            )}
          </Row>

          {get(stripeCompanySettings, 'customFields.duplicateInvoices', []).length ? (
            get(stripeCompanySettings, 'customFields.duplicateInvoices', []).map((invoice, i) => {
              return (
                <Row key={invoice.id}>
                  <Col xs={24}>
                    <Col xs={24} md={12}>
                      <Row wrap={false} gutter={[5, 5]} align="middle" justify="space-between">
                        <Text renderAs="p" variant="b" size="sm">
                          {t('duplicateInvoice')} #{i + 1}
                        </Text>

                        <Button
                          size="xs"
                          type="secondary"
                          onClick={() => openConfirmDupInvoiceSettingRemoval({ invoiceIndex: i })}
                        >
                          {t('removeDuplicateInvoice')}
                        </Button>
                      </Row>
                    </Col>

                    <Col xs={24} md={12}>
                      <FormItem label={t('customFields')} hidden={!stripeCustomerId}>
                        <DynamicCustomFieldFormItems
                          t={t}
                          maxItems={4}
                          disabled={disabled}
                          dataSource={get(invoice, 'fields')}
                          onChange={fields => handleStripeDuplicateInvoiceChange(i, { fields })}
                        />
                      </FormItem>
                    </Col>

                    <FormItem>
                      <Spinner spinning={ratesQuery.isFetching}>
                        <Transfer
                          t={t}
                          disabled={disabled}
                          dataSource={get(ratesQuery, 'data', [])}
                          leftListTitle={t('unassignedProducts')}
                          rightListTitle={t('productsAssociatedToInvoice') + ` #${i + 1}`}
                          selectableValues={unasignedRates}
                          initialSelectedItems={get(
                            stripeCompanySettings,
                            `customFields.duplicateInvoices[${i}].productsIds`,
                            [],
                          )}
                          onChange={productsIds => {
                            handleStripeDuplicateInvoiceChange(i, { productsIds });
                          }}
                        />
                      </Spinner>
                    </FormItem>
                  </Col>
                </Row>
              );
            })
          ) : (
            <Empty description={t('noSettingsHaveBeenAdded')}>
              <Row justify="center">
                <Col>
                  <Button
                    size="xs"
                    icon={<PlusOutlined />}
                    onClick={() =>
                      handleStripeDuplicateInvoiceChange(
                        [get(stripeCompanySettings, 'customFields.duplicateInvoices', []).length],
                        {
                          id: +new Date(),
                          fields: [],
                          productIds: [],
                        },
                      )
                    }
                  >
                    {t('addDuplicateInvoiceSetting')}
                  </Button>
                </Col>
              </Row>
            </Empty>
          )}
        </section>
      )}

      <ConfirmModal
        visible={isConfirmDupInvoiceSettingRemovalOpen}
        onCancel={closeConfirmDupInvoiceSettingRemoval}
        okText={t('yes')}
        cancelText={t('no')}
        onOk={() => {
          removeStripeDuplicateInvoice(confirmDupInvoiceSettingRemovalProps?.invoiceIndex);
          closeConfirmDupInvoiceSettingRemoval();
        }}
        message={t('sureYouWantToRemoveThisDuplicateInvoiceSetting?')}
      />
    </SettingSectionWrapper>
  );
};

export default StripeInvoiceSettingsFormItems;
