import { Descriptions, Divider, Form, Space } from 'antd';
import { get, omit } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useMutation } from 'react-query';

import { handleApiErrors } from '../../api/axiosInstance';
import { CARS_API } from '../../api/cars';
import { Button, ConfirmModal, CustomTable } from '../../components';
import VehicleMakeAutoComplete from '../../components/AutoComplete/VehicleMakeAutoComplete';
import VehicleModelAutoComplete from '../../components/AutoComplete/VehicleModelAutoComplete';
import VehicleYearAutoComplete from '../../components/AutoComplete/VehicleYearAutoComplete';
import FormItem from '../../components/Form/FormItem';
import GenericModal from '../../components/Modal/GenericModal';
import SpaceSpinner from '../../components/SpaceSpinner';
import SubmitCancelButtonGroup from '../../components/SubmitCancelButtonGroup';
import Text from '../../components/Text';
import LinkText from '../../components/Text/LinkText';
import Toast from '../../components/Toast';
import PageContainer from '../../containers/PageContainer';
import usePaginatedFiltersQuery from '../../hooks/queries/usePaginatedFiltersQuery';
import useLocationSearchQueryParser from '../../hooks/useLocationSearchQueryParser';
import useModal from '../../hooks/useModal';
import useTableSort from '../../hooks/useTableSort';
import { formatPageTitle, momentEST } from '../../utils/common';
import { ERROR_MESSAGE } from '../../utils/constants';
import { replaceCurrentPageSearchQueryParams } from '../../utils/queryParams';

const StandardUserVehiclesView = props => {
  const { t, history, location } = props;

  const [vehicleEditForm] = Form.useForm();
  const vehicleToEditYear = Form.useWatch('year', vehicleEditForm);
  const [vehicleToEditMake, setVehicleToEditMake] = useState('');

  const [
    isDeleteConfirmationOpen,
    openDeleteConfirmation,
    closeDeleteConfirmation,
    vehicleToDelete,
  ] = useModal();

  const [
    isEditVehicleOpen,
    openEditVehicleModal,
    closeEditVehicleModal,
    vehicleToEdit,
  ] = useModal();

  const filters = useLocationSearchQueryParser(location);

  const updateFilters = useCallback(
    (newFilters = {}) => {
      replaceCurrentPageSearchQueryParams(history, newFilters);
    },
    [history],
  );

  const { stringTableSort, handleTableSort } = useTableSort({ model_year: 1 });

  const {
    query: carsQuery,
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
  } = usePaginatedFiltersQuery({
    queryKey: ['fetchCars', filters?.searchTerm, stringTableSort],
    placeholderData: { documents: [], totalCount: 0 },
    queryFn: () =>
      CARS_API.fetchCars(
        filters?.searchTerm,
        paginationConfig.current,
        paginationConfig.pageSize,
        stringTableSort,
      ),
    onError: error => {
      handleApiErrors(error.response);
    },
  });

  const createVehicleMutation = useMutation(data => CARS_API.createCar(data), {
    onSuccess: data => {
      if (data !== 'Car already exists') {
        carsQuery.remove();
        closeEditVehicleModal();
        Toast({
          type: 'success',
          message: t('addVehicleSuccess'),
        });
      } else {
        Toast({
          type: 'warning',
          message: data,
        });
      }
    },
    onError: error => handleApiErrors(error.response),
  });

  const editVehicleMutation = useMutation(
    data => CARS_API.updateCar(data._id, omit(data, ['_id'])),
    {
      onSuccess: () => {
        carsQuery.remove();
        closeEditVehicleModal();
        Toast({
          type: 'success',
          message: t('updateVehicleSuccess'),
        });
      },
      onError: error => handleApiErrors(error.response),
    },
  );

  const deleteVehicleMutation = useMutation(carId => CARS_API.deleteCar(carId), {
    onSuccess: () => {
      carsQuery.remove();
      closeDeleteConfirmation();
      Toast({
        type: 'success',
        message: t('vehicleDeleteSuccess'),
      });
    },
    onError: error => handleApiErrors(error.response),
  });

  const COLUMNS = useMemo(() => {
    return [
      {
        sorter: true,
        defaultSortOrder: 'ascend',
        key: 'model_year',
        dataIndex: 'model_year',
        title: t('year'),
      },
      {
        sorter: true,
        key: 'model_make_id',
        dataIndex: 'model_make_id',
        title: t('make'),
      },
      {
        sorter: true,
        key: 'model_name',
        dataIndex: 'model_name',
        title: t('model'),
      },
      {
        sorter: true,
        key: 'created',
        dataIndex: 'created',
        title: t('dateAdded'),
        render: created => momentEST(created).format('MM-DD-YYYY HH:mm zz'),
      },
      {
        width: 200,
        sorter: false,
        key: 'actions',
        title: '',
        render: data => {
          return (
            <Space
              wrap={false}
              size="middle"
              direction="horizontal"
              split={<Divider type="vertical" style={{ borderColor: '#EEEFF1' }} />}
            >
              <LinkText
                onClick={() => {
                  const selectedVehicle = {
                    _id: get(data, '_id'),
                    year: get(data, 'model_year'),
                    make: get(data, 'model_make_id'),
                    model: get(data, 'model_name'),
                  };
                  setVehicleToEditMake(selectedVehicle.make);
                  vehicleEditForm.resetFields();
                  vehicleEditForm.setFieldsValue(selectedVehicle);
                  openEditVehicleModal(selectedVehicle);
                }}
              >
                {t('Edit')}
              </LinkText>
              <LinkText onClick={() => openDeleteConfirmation(data)}>{t('Delete')}</LinkText>
            </Space>
          );
        },
      },
    ];
  }, [t, vehicleEditForm, openEditVehicleModal, openDeleteConfirmation]);

  const handleFormSubmit = useCallback(() => {
    vehicleEditForm.validateFields().then(values => {
      if (get(vehicleToEdit, '_id')) {
        editVehicleMutation.mutateAsync({
          ...values,
          _id: get(vehicleToEdit, '_id'),
        });
      } else {
        createVehicleMutation.mutateAsync(values);
      }
    });
  }, [vehicleToEdit, vehicleEditForm, createVehicleMutation, editVehicleMutation]);

  const handleMakeChange = useCallback(
    value => {
      setVehicleToEditMake(value);
      vehicleEditForm.setFieldsValue({ make: value });
    },
    [vehicleEditForm],
  );

  return (
    <PageContainer
      title={t('standardUserVehicles')}
      sideActionComponent={
        <Button
          size="sm"
          onClick={() => {
            setVehicleToEditMake('');
            vehicleEditForm.resetFields();
            openEditVehicleModal({});
          }}
        >
          {t('createVehicle')}
        </Button>
      }
    >
      <Helmet>
        <title>{formatPageTitle('User Standard Vehicles')}</title>
      </Helmet>

      <CustomTable
        t={t}
        asyncSort
        loading={carsQuery.isFetching}
        dataSource={get(carsQuery.data, 'documents', [])}
        columns={COLUMNS}
        defaultSearchTerm={filters?.searchTerm}
        onSearchTermChange={searchTerm => updateFilters({ searchTerm })}
        scroll={{ x: 1100 }}
        onChange={({ current }, filters, sorters) => {
          handlePageChange(current);
          handleTableSort(sorters?.columnKey, sorters?.order);
        }}
        pagination={{
          pageSize: paginationConfig.pageSize,
          total: paginationConfig.total,
          current: paginationConfig.current,
          onShowSizeChange: handlePageSizeChange,
        }}
      />

      <GenericModal
        visible={isEditVehicleOpen}
        title={t(get(vehicleToEdit, '_id') ? 'updateVehicle' : 'createVehicle')}
        closable={!(createVehicleMutation.isLoading || editVehicleMutation.isLoading)}
        onCancel={closeEditVehicleModal}
        footer={
          <SubmitCancelButtonGroup
            disabled={createVehicleMutation.isLoading || editVehicleMutation.isLoading}
            loading={createVehicleMutation.isLoading || editVehicleMutation.isLoading}
            submitText={t(get(vehicleToEdit, '_id') ? 'update' : 'create')}
            onSubmit={handleFormSubmit}
            onCancel={closeEditVehicleModal}
          />
        }
      >
        {isEditVehicleOpen ? (
          <Form form={vehicleEditForm} defaultValue={vehicleToEdit}>
            <FormItem
              required
              name="year"
              label={t('year')}
              rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
            >
              <VehicleYearAutoComplete t={t} />
            </FormItem>
            <FormItem
              required
              name="make"
              label={t('make')}
              rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
            >
              <VehicleMakeAutoComplete t={t} year={vehicleToEditYear} onChange={handleMakeChange} />
            </FormItem>
            <FormItem
              required
              name="model"
              label={t('model')}
              rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
            >
              <VehicleModelAutoComplete t={t} year={vehicleToEditYear} make={vehicleToEditMake} />
            </FormItem>
          </Form>
        ) : (
          <SpaceSpinner />
        )}
      </GenericModal>

      <ConfirmModal
        closable={!deleteVehicleMutation.isLoading}
        visible={isDeleteConfirmationOpen}
        title={t('deleteVehicle')}
        message={
          <Space direction="vertical" size="large">
            <Text>{t('sureYouWantToDeleteThisVehicle')}</Text>

            <Descriptions column={1} size="small" bordered>
              <Descriptions.Item label={t('year')}>
                <Text>{get(vehicleToDelete, 'model_year')}</Text>
              </Descriptions.Item>
              <Descriptions.Item label={t('make')}>
                <Text>{get(vehicleToDelete, 'model_make_id')}</Text>
              </Descriptions.Item>
              <Descriptions.Item label={t('model')}>
                <Text>{get(vehicleToDelete, 'model_name')}</Text>
              </Descriptions.Item>
            </Descriptions>
          </Space>
        }
        onCancel={closeDeleteConfirmation}
        okText={t('yes')}
        onOk={() => {
          deleteVehicleMutation.mutateAsync(get(vehicleToDelete, '_id'));
        }}
      />
    </PageContainer>
  );
};

export default withNamespaces()(StandardUserVehiclesView);
