import { get } from 'lodash';
import React, { useMemo } from 'react';

import { renderBooleanCheckmarks } from '../../../utils/tables';
import { CustomTable } from '../..';
import LinkText from '../../Text/LinkText';

const MetabaseReportsTable = props => {
  const { t, dataSource, onEditClick, ...rest } = props;

  const COLUMNS = useMemo(() => {
    return [
      {
        key: 'name',
        title: t('name'),
        dataIndex: 'name',
      },
      {
        key: 'metabaseId',
        title: t('metabaseId'),
        dataIndex: 'dashboardId',
      },
      {
        align: 'center',
        key: 'visibleInReports',
        title: t('enabled'),
        ...renderBooleanCheckmarks('visibleInReports'),
      },
      {
        key: 'requiredFilterKeys',
        title: t('requiredFilterKeys'),
        render: data => {
          const filters = [];
          Object.values(data).forEach((val, i) => {
            if (!!get(val, 'required') && !!get(val, 'keyName')) {
              filters.push(
                t('metabaseKeyNamePair', {
                  name: Object.keys(data)[i],
                  keyname: get(val, 'keyName', null),
                }),
              );
            }
          });

          return (
            <ul>
              {filters.map(filter => (
                <li key={filter}>{filter}</li>
              ))}
            </ul>
          );
        },
      },
      {
        key: 'optionalFilterKeys',
        title: t('optionalFilterKeys'),
        render: data => {
          const filters = [];
          Object.values(data).forEach((val, i) => {
            if (!get(val, 'required') && !!get(val, 'keyName')) {
              filters.push(
                t('metabaseKeyNamePair', {
                  name: Object.keys(data)[i],
                  keyname: get(val, 'keyName', null),
                }),
              );
            }
          });

          return (
            <ul>
              {filters.map(filter => (
                <li key={filter}>{filter}</li>
              ))}
            </ul>
          );
        },
      },
      {
        key: 'flagsmithName',
        title: t('flagsmithName'),
        dataIndex: 'flagsmithName',
        render: flagsmithName => flagsmithName || '-',
      },
      {
        key: 'sendGroupIdAsString',
        title: t('sendGroupIdAsString'),
        ...renderBooleanCheckmarks('sendGroupIdAsString'),
      },
      {
        width: 150,
        key: 'actions',
        title: t('actions'),
        render: data => <LinkText onClick={() => onEditClick(data._id)}>{t('Edit')}</LinkText>,
      },
    ];
  }, [t, onEditClick]);

  return (
    <CustomTable
      {...rest}
      scroll={{ x: 1400 }}
      showSearchInput={false}
      rowKey={data => data?.dashboardId}
      columns={COLUMNS}
      dataSource={dataSource}
    />
  );
};

export default MetabaseReportsTable;
