import { INTERNAL_LINKS } from '../enum';
import UserDetails from '../views/userdetails';
import CompanyDetailsView from './company-details';
import ComplianceWorklistView from './compliance-worklist';
import ComplianceWorklistDetails from './compliance-worklist-details';
import CountryRatesView from './country-rates';
import CreateCountryView from './create-country';
import CreateCountryRateView from './create-country-rate';
import CustomPlansView from './custom-plans';
import FAVRRatesView from './favr-rates';
import GlobalSettingsView from './global-settings';
import InsuranceCompaniesView from './insurance-companies';
import ManageSystemAdminsView from './manage-system-admins';
import MetabaseReportUpdateView from './metabase-report';
import SelectCompanyView from './select-companies';
import StandardUserVehiclesView from './standard-user-vehicles';
import StandardVehiclesView from './standard-vehicles';
import SystemAdminDetails from './system-admin-details';
import TripAnomaliesView from './trip-anomalies';
import UpdateCountryView from './update-country';
import UpdateCountryRateView from './update-country-rate';

const SystemAdminViews = [
  {
    path: `${INTERNAL_LINKS.SELECT_COMPANY}/:id`,
    component: CompanyDetailsView,
  },
  {
    path: INTERNAL_LINKS.SELECT_COMPANY,
    component: SelectCompanyView,
  },
  {
    path: `${INTERNAL_LINKS.UPDATE_COUNTRY}/:id`,
    component: UpdateCountryView,
  },
  {
    path: INTERNAL_LINKS.CREATE_COUNTRY,
    component: CreateCountryView,
  },
  {
    path: `${INTERNAL_LINKS.UPDATE_COUNTRY_RATES}/:id`,
    component: UpdateCountryRateView,
  },
  {
    path: INTERNAL_LINKS.CREATE_COUNTRY_RATES,
    component: CreateCountryRateView,
  },
  {
    path: INTERNAL_LINKS.COUNTRY_RATES,
    component: CountryRatesView,
  },
  {
    path: INTERNAL_LINKS.COMPLIANCE_WORKLIST,
    component: ComplianceWorklistView,
  },
  {
    path: `${INTERNAL_LINKS.UPDATE_SYSTEM_ADMINS}/:id`,
    component: SystemAdminDetails,
  },
  {
    path: INTERNAL_LINKS.CUSTOM_PLANS,
    component: CustomPlansView,
  },
  {
    path: INTERNAL_LINKS.SYSTEM_ADMINS,
    component: ManageSystemAdminsView,
  },
  {
    path: INTERNAL_LINKS.GLOBAL_SETTINGS,
    component: GlobalSettingsView,
  },
  {
    path: INTERNAL_LINKS.FAVR_STANDARD_VEHICLES,
    component: StandardVehiclesView,
  },
  {
    path: INTERNAL_LINKS.USER_STANDARD_VEHICLES,
    component: StandardUserVehiclesView,
  },
  {
    path: `${INTERNAL_LINKS.USER_DETAILS}/:id`,
    component: UserDetails,
  },
  {
    exact: true,
    path: INTERNAL_LINKS.COMPLIANCE_WORKLIST_DETAILS(),
    component: ComplianceWorklistDetails,
  },
  {
    exact: true,
    path: INTERNAL_LINKS.UPDATE_METABASE_REPORT(),
    component: MetabaseReportUpdateView,
  },
  {
    exact: true,
    path: INTERNAL_LINKS.CREATE_METABASE_REPORT,
    component: MetabaseReportUpdateView,
  },
  {
    path: INTERNAL_LINKS.TRIP_ANOMALIES,
    component: TripAnomaliesView,
  },
  {
    exact: true,
    path: INTERNAL_LINKS.INSURANCE_COMPANIES,
    component: InsuranceCompaniesView,
  },
  {
    exact: true,
    path: INTERNAL_LINKS.FAVR_RATES,
    component: FAVRRatesView,
  },
];

export default SystemAdminViews;
