import { get } from 'lodash';

import { formatQueryRange } from '../utils/common';
import { removeFalseyParams } from '../utils/queryParams';
import { handleApiCalls } from './axiosInstance';
import BaseAPI from './baseAPI';

class TripAnomaliesAPI extends BaseAPI {
  constructor() {
    super({ suffix: 'trip-anomalies' });
  }

  /**
   * @param {object} filters
   * @param {string} filters.userId
   * @param {string} filters.companyId
   * @param {"gps-litter" | "high-speed"} filters.type
   * @param {string} filters.fromDate
   * @param {string} filters.toDate
   */
  fetchTripAnomalies = async (filters = {}, pageNumber, pageSize) => {
    const params = removeFalseyParams(filters);
    const range = formatQueryRange(pageNumber, pageSize);
    const response = await handleApiCalls('get', this.baseUrl, {
      params: { ...params, range },
    });
    return response.data;
  };

  /**
   * @param {string} anomalyId
   */
  fetchTripAnomalyCoordinates = async anomalyId => {
    const response = await handleApiCalls('get', this.baseUrl + `/${anomalyId}`);
    return {
      journeyGeoJson: {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: get(response, 'data.journeyGeoJson.geometry.coordinates', []),
        },
      },
    };
  };

  /**
   * @param {string} anomalyId
   * @param {"accept" | "reject"} action
   */
  reviewTripAnomaly = async (anomalyId, action) => {
    const response = await handleApiCalls('patch', this.baseUrl + `/${anomalyId}/review`, {
      action,
    });
    return response.data;
  };
}

export default TripAnomaliesAPI;
