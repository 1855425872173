import { Col, Form, Row } from 'antd';
import { get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, withNamespaces } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { AUTH_API, signInUser } from '../../api/auth';
import { handleApiCalls, handleApiErrors } from '../../api/axiosInstance';
import { fetchCountryConfig } from '../../api/common';
import { AddressForm } from '../../components';
import FullLogo from '../../components/FullLogo';
import GenericModal from '../../components/Modal/GenericModal';
import RegistrationForm from '../../components/RegistrationForm';
import SubmitCancelButtonGroup from '../../components/SubmitCancelButtonGroup';
import FadedText from '../../components/Text/FadedText';
import LinkText from '../../components/Text/LinkText';
import Toast from '../../components/Toast';
import { INTERNAL_LINKS } from '../../enum';
import useModal from '../../hooks/useModal';
import i18n from '../../i18n';
import { updateSupportedCountries } from '../../stores/actions/common';
import { updateProfile } from '../../stores/actions/profile';
import { formatPageTitle } from '../../utils/common';
import { setAnalyticsUserProperties } from '../../utils/googleAnalytics';
import { PENDO_ANALYTICS } from '../../utils/pendoAnalytics';
import { SENTRY_ANALYTICS } from '../../utils/sentry';
import { selectStoreCountries, useStoreSelector } from '../../utils/storeSelectors';
import { normalizeUserSchema } from '../../utils/users';
import classNames from './styles.module.scss';

const UserAccountActivate = props => {
  const { t, ...rest } = props;

  const dispatch = useDispatch();
  const supportedCountries = useStoreSelector(selectStoreCountries, []);

  const [errorType, setErrorType] = useState(0);
  const [showError, setShowError] = useState(false);
  const [userToActivate, setUserToActivate] = useState();
  const [hasAddress, setHasAddress] = useState(true);
  const [userAddress, setUserAddress] = useState({});

  const [addressFormInstance] = Form.useForm();

  const [isAddressModalVisible, openAddressModal, closeAddressModal] = useModal();

  const tempAuthTokenQuery = useQuery({
    enabled: !!userToActivate?._id,
    cacheTime: 0,
    staleTime: 0,
    queryKey: ['temp-token'],
    queryFn: () => AUTH_API.generateTempAuthToken(userToActivate?._id),
    onSuccess: tempToken => {
      sessionStorage.setItem('tempToken', tempToken);
    },
    onError: error => {
      setErrorType(1);
      handleApiErrors(error.response);
    },
  });

  useQuery({
    enabled: !supportedCountries?.length && !hasAddress,
    queryKey: ['supported-countries'],
    queryFn: fetchCountryConfig,
    onSuccess: countries => dispatch(updateSupportedCountries(countries)),
    onError: () => {
      Toast({
        type: 'error',
        message: t('errorLoadingSupportedCountries'),
      });
    },
  });

  const tokenVerificationQuery = useQuery({
    queryKey: ['activate-account', props.match.params.token],
    queryFn: async () =>
      handleApiCalls(
        'get',
        `${process.env.REACT_APP_HOST_API}auth/activate-account/${props.match.params.token}`,
      ),
    onSuccess: response => {
      const data = get(response, 'data.data');
      setShowError(false);
      setHasAddress(!!get(data, 'homeAddress.streetOne'));
      setUserToActivate({
        _id: data._id,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        status: data.status,
        companyID: data.companyID,
      });
    },
    onError: err => {
      const IS_TOKEN_EXPIRED =
        err.response && err.response.data && /expired/gi.test(err.response.data);
      const IS_TOKEN_INVALID =
        err.response && err.response.data && /invalid/gi.test(err.response.data);
      const IS_ACTIVATED =
        err.response && err.response.data && /already activated/gi.test(err.response.data);

      if (err.response && (IS_TOKEN_EXPIRED || IS_TOKEN_INVALID)) {
        setErrorType(1);
      } else if (IS_ACTIVATED) {
        setErrorType(3);
      } else if (err.response && err.response.status === 404) {
        setErrorType(0);
      } else {
        setErrorType(0);
      }
      setShowError(true);
    },
  });

  const handleLogin = useCallback(
    async model => {
      try {
        let loginResult = await signInUser(model.email, model.password);

        if (loginResult.user) {
          setAnalyticsUserProperties(loginResult.user);
          PENDO_ANALYTICS.update(loginResult.user, loginResult.company);
          SENTRY_ANALYTICS.update(loginResult.user);

          const normalizedUser = normalizeUserSchema(loginResult.user);
          const language = normalizedUser?.preferences?.language || 'en';
          i18n.changeLanguage(language);

          dispatch(updateProfile(normalizedUser));
        }
      } catch (error) {
        handleApiErrors(error.response, () => {
          Toast({
            type: 'error',
            message: t('errorLoginIn'),
          });
        });
      }
    },
    [t, dispatch],
  );

  const activateAccountMutation = useMutation(
    data =>
      handleApiCalls(
        'post',
        `${process.env.REACT_APP_HOST_API}auth/activate-account/${props.match.params.token}`,
        data,
      ),
    {
      onSuccess: async () => {
        closeAddressModal();
        Toast({
          type: 'success',
          message: t('accountActivationSuccess'),
        });
        await handleLogin(userToActivate);
        setTimeout(() => {
          props.history.push(INTERNAL_LINKS.DASHBOARD);
        }, 1000);
      },
      onError: err => {
        closeAddressModal();
        if (
          err.response &&
          err.response.status === 422 &&
          (err.response.data === 'The activation link is expired.' ||
            err.response.data === 'It looks like you clicked on an expired link')
        ) {
          setErrorType(1);
        } else if (
          err.response &&
          err.response.status === 422 &&
          err.response.data === 'This account has been already activated.'
        ) {
          setErrorType(3);
        } else if (err.response && err.response.status === 404) {
          setErrorType(0);
        } else {
          setErrorType(0);
          handleApiErrors(err.response);
        }
        setShowError(true);
      },
    },
  );

  const resendEmail = useMutation(
    () =>
      handleApiCalls('post', `${process.env.REACT_APP_HOST_API}auth/resend-activate-email`, {
        token: props.match.params.token,
      }),
    {
      onSuccess: () => {
        Toast({
          type: 'success',
          message: t('activationEmailSendSuccessful'),
        });
      },
      onError: e => {
        handleApiErrors(e.response, () => {
          Toast({
            type: 'error',
            message: t('resendingEmailFailed'),
          });
        });
      },
    },
  );

  const handleActivate = useCallback(
    userData => {
      const newUserData = { ...userToActivate, ...userData };
      setUserToActivate(newUserData);

      if (hasAddress) {
        activateAccountMutation.mutateAsync(newUserData);
      } else {
        addressFormInstance.resetFields();
        setUserAddress({});
        setTimeout(() => {
          openAddressModal();
        }, 100);
      }
    },
    [userToActivate, addressFormInstance, hasAddress, openAddressModal, activateAccountMutation],
  );

  const handleAddressModalClose = useCallback(() => {
    addressFormInstance.resetFields();
    setUserAddress();
    closeAddressModal();
  }, [addressFormInstance, closeAddressModal]);

  useEffect(() => {
    return () => {
      sessionStorage.removeItem('tempToken');
    };
  }, []);

  if (tokenVerificationQuery.isFetching || resendEmail.isLoading || tempAuthTokenQuery.isFetching) {
    return (
      <>
        <Helmet>
          <title>{formatPageTitle('Activate Account')}</title>
        </Helmet>

        <Row justify="center" align={'middle'} className={classNames.activateView}>
          <div className="d-flex-center">
            <FadedText>{t('loading...')}</FadedText>
          </div>
        </Row>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{formatPageTitle('Activate Account')}</title>
      </Helmet>

      <Row
        justify="center"
        align={showError ? 'middle' : 'top'}
        className={classNames.activateView}
      >
        <Col flex={1}>
          <Row justify="center" style={{ marginBottom: 30 }}>
            <Col>
              <Link to={INTERNAL_LINKS.LOGIN}>
                <FullLogo />
              </Link>
            </Col>
          </Row>

          {tempAuthTokenQuery.isFetched &&
            tokenVerificationQuery.isFetched &&
            !resendEmail.isLoading &&
            !showError && (
              <RegistrationForm
                activating={activateAccountMutation.isLoading}
                user={userToActivate}
                fieldValues={handleActivate}
                t={t}
                props={rest}
              />
            )}

          {showError && errorType === 1 && (
            <div className="d-flex-center f-dir-column">
              <FadedText>
                <Trans
                  t={t}
                  i18nKey="emailVerificationLinkExpiredRequestNewHere"
                  components={[
                    <LinkText variant="strong" onClick={resendEmail.mutateAsync}>
                      dummy
                    </LinkText>,
                  ]}
                />
              </FadedText>
            </div>
          )}
          {showError && errorType === 3 && (
            <div className="d-flex-center f-dir-column">
              <FadedText>{t('accountAlreadyBeenActivated')}</FadedText>
              <br />
              <Link to={INTERNAL_LINKS.LOGIN}>
                <LinkText variant="strong">{t('loginToKliks')}</LinkText>
              </Link>
            </div>
          )}
          {showError && errorType === 2 && (
            <div className="d-flex-center">
              <FadedText>{t('emailVerificationLinkExpired')}</FadedText>
            </div>
          )}
          {showError && errorType === 0 && (
            <div className="d-flex-center">
              <FadedText>{t('unexpectedErrorContactAdmin')}</FadedText>
            </div>
          )}
        </Col>
      </Row>

      <GenericModal
        closable={!activateAccountMutation.isLoading}
        visible={isAddressModalVisible}
        onCancel={handleAddressModalClose}
        title={t('pleaseEnterHomeAddress')}
        footer={
          <SubmitCancelButtonGroup
            onCancel={handleAddressModalClose}
            loading={activateAccountMutation.isLoading}
            onSubmit={() => {
              addressFormInstance.validateFields().then(homeAddress => {
                activateAccountMutation.mutateAsync({
                  ...userToActivate,
                  homeAddress,
                });
              });
            }}
          />
        }
      >
        {userAddress && (
          <AddressForm
            t={t}
            formInstance={addressFormInstance}
            countries={supportedCountries}
            values={userAddress}
            onValueChange={newvalues =>
              setUserAddress(currentvals => ({ ...(currentvals || {}), ...newvalues }))
            }
          />
        )}
      </GenericModal>
    </>
  );
};

export default withNamespaces()(UserAccountActivate);
