import { Col, DatePicker, Descriptions, Row, Space } from 'antd';
import { get, isEmpty, omit, sortBy } from 'lodash';
import moment from 'moment-timezone';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { handleApiErrors } from '../../api/axiosInstance';
import { TRIP_API } from '../../api/trips';
import { Button } from '../../components';
import Box from '../../components/Box';
import FormItem from '../../components/Form/FormItem';
import GenericModal from '../../components/Modal/GenericModal';
import PageBreadcrumbs from '../../components/PageBreadcrumbs';
import SpaceSpinner from '../../components/SpaceSpinner';
import SubmitCancelButtonGroup from '../../components/SubmitCancelButtonGroup';
import Text from '../../components/Text';
import LinkText from '../../components/Text/LinkText';
import Toast from '../../components/Toast';
import PageContainer from '../../containers/PageContainer';
import { INTERNAL_LINKS, STATUS_LIST } from '../../enum';
import withAuthentication from '../../hocs/withAuthentication';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import useLocationSearchQueryParser from '../../hooks/useLocationSearchQueryParser';
import useModal from '../../hooks/useModal';
import useSentryReplay from '../../hooks/useSentryReplay.js';
import { formatPageTitle, momentTimezone, replaceValueInArrayByID } from '../../utils/common';
import { formatNumberWithDistanceUnit } from '../../utils/numbers';
import { replaceCurrentPageSearchQueryParams } from '../../utils/queryParams';
import {
  selectStoreCompanySettings,
  selectStoreCountryByCode,
  selectStoreCurrentAuthUser,
  useStoreSelector,
} from '../../utils/storeSelectors';
import MileageItemCard from './MileageItemCard';
import classNames from './styles.module.scss';
import UserMileageCapCardContent from './UserMileageCapCardContent';

const doesWeekContainTwoMonths = date => {
  return date.startOf('week').month() !== date.endOf('week').month();
};

const DailyMileageLogView = props => {
  const { t, history, location } = props;

  const unmaskSentryProps = useSentryReplay();

  const initialQueryParams = useMemo(
    () => ({
      isMonthly: true,
      selectedDate: moment().toISOString(),
    }),
    [],
  );

  const authUser = useStoreSelector(selectStoreCurrentAuthUser);
  const companySettings = useStoreSelector(selectStoreCompanySettings);
  const queryParams = useLocationSearchQueryParser(location, initialQueryParams);

  const [
    isConfirmTripSubmitOpen,
    openConfirmTripSubmitModal,
    closeConfirmTripSubmitModal,
  ] = useModal();

  const [isMonthlyView, setIsMonthlyView] = useState(queryParams.isMonthly);
  const [selectedDate, setSelectedDate] = useState(moment(queryParams.selectedDate));
  const [dateRange, setDateRange] = useState([]);

  const [mileageItemsToCreate, setMileageItemsToCreate] = useState({});
  const [mileageItemsToUpdate, setMileageItemsToUpdate] = useState({});
  const [mileageItemsToDelete, setMileageItemsToDelete] = useState({});

  const toggleMonthlyView = useCallback(() => {
    setIsMonthlyView(state => {
      const isMonthly = !state;

      if (!isMonthly) {
        setSelectedDate(date => {
          const newDate = date.startOf('month');
          replaceCurrentPageSearchQueryParams(history, {
            isMonthly,
            selectedDate: newDate.toISOString(),
          });

          return newDate;
        });
      } else {
        replaceCurrentPageSearchQueryParams(history, {
          isMonthly,
          selectedDate: selectedDate.toISOString(),
        });
      }

      return isMonthly;
    });
  }, [history, selectedDate]);

  const _setMileageItemsState = ({ tripId, journeyDate, journeyDistance }, setStateCb) => {
    setStateCb(state => ({
      ...state,
      [journeyDate]: { tripId, journeyDate, journeyDistance },
    }));
  };

  const handleMileageCreate = values => {
    if (parseFloat(values?.journeyDistance) > 0) {
      _setMileageItemsState(values, setMileageItemsToCreate);
    } else {
      setMileageItemsToCreate(tripsToCreate => {
        return omit(tripsToCreate, [values.journeyDate]);
      });
    }
  };
  const handleMileageDelete = values => {
    _setMileageItemsState(values, setMileageItemsToDelete);
    setMileageItemsToUpdate(state => omit(state, [values.journeyDate]));
  };
  const handleMileageDeleteRevert = date => setMileageItemsToDelete(state => omit(state, [date]));
  const handleMileageUpdate = values => {
    _setMileageItemsState(values, setMileageItemsToUpdate);
    handleMileageDeleteRevert(values.journeyDate);
  };

  const handleDateChange = useCallback(
    momentDate => {
      const year = momentDate.year();

      let dateYear = moment().year(year);
      let startDate;
      let endDate;

      if (isMonthlyView) {
        const month = momentDate.month();
        startDate = dateYear.clone().month(month).startOf('month');
        endDate = dateYear.clone().month(month).endOf('month');
      } else {
        const week = momentDate.week();
        startDate = dateYear.clone().week(week).startOf('week');
        endDate = dateYear.clone().week(week).endOf('week');
      }

      const cleanStartOfWeekDate = startDate.format('YYYY-MM-DD');
      const cleanEndOfWeekDate = endDate.format('YYYY-MM-DD');
      setSelectedDate(momentDate);
      setDateRange([cleanStartOfWeekDate, cleanEndOfWeekDate]);

      replaceCurrentPageSearchQueryParams(history, { selectedDate: momentDate.toISOString() });

      setMileageItemsToCreate({});
      setMileageItemsToUpdate({});
      setMileageItemsToDelete({});
    },
    [history, isMonthlyView],
  );

  useEffect(() => {
    handleDateChange(selectedDate);
    // eslint-disable-next-line
  }, []);

  useDidUpdateEffect(() => {
    handleDateChange(selectedDate);
  }, [isMonthlyView]);

  const queryClient = useQueryClient();

  const dailyMileageQuery = useQuery({
    placeholderData: [],
    queryKey: ['daily-mileage', dateRange[0], dateRange[1]],
    queryFn: () => TRIP_API.getAllMileages(dateRange[0], dateRange[1]),
    enabled: !!dateRange[0] && !!dateRange[1],
    onError: error =>
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('dailyMileageQueryError'),
        });
      }),
  });

  const _updateDailyMileageQueryData = (trips, variables) => {
    const queryKey = ['daily-mileage', dateRange[0], dateRange[1]];
    let updatedQueryData = queryClient.getQueryData(queryKey);

    trips.forEach((trip, i) => {
      updatedQueryData = replaceValueInArrayByID(
        updatedQueryData,
        {
          date: variables.trips[i].journeyDate,
          trip: {
            _id: trip._id,
            journeyStatus: trip.journeyStatus,
            journeyDistance: trip.journeyDistance,
          },
        },
        'date',
      );
    });
  };

  const createMileageMutation = useMutation(TRIP_API.createMileage, {
    onSuccess: (trips, variables) => {
      _updateDailyMileageQueryData(trips, variables);
    },
    onError: error =>
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('createMileageMutationError'),
        });
      }),
  });

  const updateMileageMutation = useMutation(TRIP_API.updateMileage, {
    onSuccess: (trips, variables) => {
      _updateDailyMileageQueryData(trips, variables);
    },
    onError: error =>
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('updateMileageMutationError'),
        });
      }),
  });

  const deleteTripsMutation = useMutation(TRIP_API.deleteTrip, {
    onSuccess: (_, tripIds) => {
      const queryKey = ['daily-mileage', dateRange[0], dateRange[1]];
      let updatedQueryData = queryClient.getQueryData(queryKey);

      tripIds.forEach(tripId => {
        const updateIndex = updatedQueryData.findIndex(data => data?.trip?._id === tripId);
        updatedQueryData[updateIndex].trip = null;
      });
    },
    onError: error =>
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('deleteTripsMutationMutationError'),
        });
      }),
  });

  const pendingTrips = useMemo(() => {
    let trips = [];

    if (!dailyMileageQuery.isFetching && Array.isArray(dailyMileageQuery.data)) {
      dailyMileageQuery.data.forEach(item => {
        if (
          !mileageItemsToUpdate.hasOwnProperty(item.date) &&
          get(item, 'trip.journeyStatus') === STATUS_LIST().Status.PENDING
        ) {
          trips.push({
            journeyDate: item.date,
            journeyDistance: get(item, 'trip.journeyDistance'),
            tripId: get(item, 'trip._id'),
          });
        }
      });
    }

    return trips;
  }, [dailyMileageQuery.data, dailyMileageQuery.isFetching, mileageItemsToUpdate]);

  const handleDailyMileageSave = async (shouldSubmit = false) => {
    const hasMileageToCreate = !!Object.values(mileageItemsToCreate).length;
    const hasMileageToUpdate =
      !!Object.values(mileageItemsToUpdate).length || (shouldSubmit && !!pendingTrips.length);
    const hasTripsToDelete = !!Object.values(mileageItemsToDelete).length;

    if (hasTripsToDelete) {
      try {
        await deleteTripsMutation.mutateAsync(
          Object.values(mileageItemsToDelete).map(trip => trip.tripId),
        );
      } catch (error) {
        return;
      }
    }

    if (hasMileageToCreate) {
      try {
        await createMileageMutation.mutateAsync({
          trips: Object.values(mileageItemsToCreate),
          submitMileage: shouldSubmit,
        });
      } catch (error) {
        return;
      }
    }

    if (hasMileageToUpdate) {
      try {
        await updateMileageMutation.mutateAsync({
          trips: shouldSubmit
            ? [...pendingTrips, ...Object.values(mileageItemsToUpdate)]
            : Object.values(mileageItemsToUpdate),
          submitMileage: shouldSubmit,
        });
      } catch (error) {
        return;
      }
    }

    if (hasMileageToCreate || hasMileageToUpdate || hasTripsToDelete) {
      closeConfirmTripSubmitModal();

      Toast({
        type: 'open',
        message: t('mileageSaveSucess'),
      });

      setMileageItemsToCreate({});
      setMileageItemsToUpdate({});
      setMileageItemsToDelete({});

      dailyMileageQuery.refetch();
      queryClient.refetchQueries({
        exact: false,
        queryKey: ['fetchUserMileageCap'],
      });
    }
  };

  const country = useMemo(
    () => selectStoreCountryByCode(get(authUser, 'profile.group.productId.country', 'US')),
    [authUser],
  );

  const areItemsEmpty = useMemo(() => {
    return (
      isEmpty(mileageItemsToCreate) &&
      isEmpty(mileageItemsToUpdate) &&
      isEmpty(mileageItemsToDelete)
    );
  }, [mileageItemsToCreate, mileageItemsToUpdate, mileageItemsToDelete]);

  const handleDisabledDatePickerDates = useCallback(
    currentDate => {
      const currentDateTimezone = moment(currentDate);
      const isNextYear = currentDateTimezone.year() > moment().year();
      const isSameYear = currentDateTimezone.year() === moment().year();
      if (isMonthlyView) {
        const isHigherMonthNumber = currentDateTimezone.month() > moment().month();
        return isNextYear || (isSameYear && isHigherMonthNumber);
      }

      const isHigherWeekNumber = currentDateTimezone.week() > moment().week();
      return isNextYear || (isSameYear && isHigherWeekNumber);
    },
    [isMonthlyView],
  );

  const displaySecondMileageCap = useMemo(() => {
    return (
      !!companySettings?.mileageCap && !isMonthlyView && doesWeekContainTwoMonths(selectedDate)
    );
  }, [companySettings, isMonthlyView, selectedDate]);

  const tripsToSubmit = useMemo(() => {
    return sortBy(
      [
        ...pendingTrips,
        ...Object.values(mileageItemsToUpdate),
        ...Object.values(mileageItemsToCreate),
      ],
      'journeyDate',
    );
  }, [pendingTrips, mileageItemsToUpdate, mileageItemsToCreate]);

  return (
    <PageContainer
      {...unmaskSentryProps}
      title={
        <PageBreadcrumbs
          items={[
            {
              label: t('trips'),
              onClick: () => history.push(INTERNAL_LINKS.TRIPS),
            },
            { label: t('dailyMileageLog') },
          ]}
        />
      }
      sideActionComponent={
        <Space direction="horizontal" size="middle">
          <Button
            size="small"
            type="secondary"
            loading={
              updateMileageMutation.isLoading ||
              createMileageMutation.isLoading ||
              deleteTripsMutation.isLoading
            }
            disabled={
              areItemsEmpty ||
              updateMileageMutation.isLoading ||
              createMileageMutation.isLoading ||
              deleteTripsMutation.isLoading
            }
            onClick={() => handleDailyMileageSave()}
          >
            {t('saveMileage')}
          </Button>

          <Button
            size="small"
            loading={
              updateMileageMutation.isLoading ||
              createMileageMutation.isLoading ||
              deleteTripsMutation.isLoading
            }
            disabled={
              (areItemsEmpty && !pendingTrips.length) ||
              updateMileageMutation.isLoading ||
              createMileageMutation.isLoading ||
              deleteTripsMutation.isLoading
            }
            onClick={() => openConfirmTripSubmitModal()}
          >
            {t('saveAndSubmitMileage')}
          </Button>
        </Space>
      }
    >
      <Helmet>
        <title>{formatPageTitle('Daily Mileage Log')}</title>
      </Helmet>

      <Space direction="vertical" size="large">
        <Row align="middle" justify={{ xs: 'end', md: 'space-between' }} gutter={[16, 16]}>
          <Col xs={24} md={12} lg={6}>
            <FormItem
              label={
                <Row gutter={6} wrap={false}>
                  <Col>{t(isMonthlyView ? 'selectMonth' : 'selectWeek')}</Col>
                  <Col>
                    <LinkText size="xs" onClick={toggleMonthlyView}>
                      ({t(isMonthlyView ? 'weeklyView' : 'monthlyView')})
                    </LinkText>
                  </Col>
                </Row>
              }
              extra={
                isMonthlyView
                  ? [
                      selectedDate.startOf('month').format('MMM DD'),
                      selectedDate.endOf('month').format('MMM DD'),
                    ].join(' - ')
                  : [
                      selectedDate.startOf('week').format('MMM DD'),
                      selectedDate.endOf('week').format('MMM DD'),
                    ].join(' - ')
              }
            >
              <DatePicker
                allowClear={false}
                value={selectedDate}
                picker={isMonthlyView ? 'month' : 'week'}
                onChange={handleDateChange}
                disabledDate={handleDisabledDatePickerDates}
              />
            </FormItem>
          </Col>

          <Col xs={24} lg={12} xl={6}></Col>

          {!!companySettings?.mileageCap && (
            <Col xs={24} lg={12} xl={displaySecondMileageCap ? 12 : 6}>
              <Box className={classNames.mileageCapCard}>
                <Text variant="h5">{t('yourMileageCap')}</Text>

                <Row align="middle" gutter={[16, 16]}>
                  <Col flex={1}>
                    <UserMileageCapCardContent
                      t={t}
                      userId={authUser.profile._id}
                      selectedDate={
                        !isMonthlyView && doesWeekContainTwoMonths(selectedDate)
                          ? selectedDate.clone().startOf('week')
                          : selectedDate.clone()
                      }
                    />
                  </Col>

                  {displaySecondMileageCap && (
                    <Col flex={1}>
                      <UserMileageCapCardContent
                        t={t}
                        userId={authUser.profile._id}
                        selectedDate={selectedDate.clone().endOf('week')}
                      />
                    </Col>
                  )}
                </Row>
              </Box>
            </Col>
          )}
        </Row>

        {dailyMileageQuery.isFetching && <SpaceSpinner />}

        <Row gutter={[10, 10]}>
          {!dailyMileageQuery.isFetching &&
            dailyMileageQuery.data?.map(mileageItem => {
              return (
                <MileageItemCard
                  t={t}
                  item={mileageItem}
                  onMileageCreate={handleMileageCreate}
                  onMileageUpdate={handleMileageUpdate}
                  onMileageDelete={handleMileageDelete}
                  distanceUnit={country.distanceShort}
                  disabled={
                    updateMileageMutation.isLoading ||
                    createMileageMutation.isLoading ||
                    deleteTripsMutation.isLoading
                  }
                />
              );
            })}
        </Row>
      </Space>

      <GenericModal
        {...unmaskSentryProps}
        centered
        bodyStyle={{ overflowY: 'auto' }}
        closable={
          !(
            updateMileageMutation.isLoading ||
            createMileageMutation.isLoading ||
            deleteTripsMutation.isLoading
          )
        }
        visible={isConfirmTripSubmitOpen}
        onCancel={closeConfirmTripSubmitModal}
        title={t('confirmTrips')}
        footer={
          <SubmitCancelButtonGroup
            disabled={
              updateMileageMutation.isLoading ||
              createMileageMutation.isLoading ||
              deleteTripsMutation.isLoading
            }
            loading={
              updateMileageMutation.isLoading ||
              createMileageMutation.isLoading ||
              deleteTripsMutation.isLoading
            }
            onCancel={closeConfirmTripSubmitModal}
            onSubmit={() => handleDailyMileageSave(true)}
          />
        }
      >
        <Space direction="vertical" size="small">
          <Text>{t('theFollowingTripsWillBeSubmitted')}</Text>

          <Descriptions bordered column={1} size="small">
            {tripsToSubmit.map(trip => (
              <Descriptions.Item
                key={trip.journeyDate}
                label={momentTimezone(trip.journeyDate, 'YYYY-MM-DD').format('MMMM DD, YYYY')}
              >
                <Text textAlign="right">
                  {formatNumberWithDistanceUnit(trip.journeyDistance, country.distanceShort)}
                </Text>
              </Descriptions.Item>
            ))}
          </Descriptions>
        </Space>
      </GenericModal>
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(DailyMileageLogView));
