import { Col, Space } from 'antd';
import React from 'react';

import Text from '../../../components/Text';

const SettingSectionWrapper = props => {
  const { title, children, ...rest } = props;

  return (
    <Col xs={24} md={12} lg={8} {...rest}>
      {title && (
        <Text renderAs="p" variant="b" size="sm" style={{ marginBottom: 16 }}>
          {title}
        </Text>
      )}

      <Space direction="vertical" size="small">
        {children}
      </Space>
    </Col>
  );
};

export default SettingSectionWrapper;
