import { STATUS_LIST, USER_ROLES } from '../enum';
import { formatQueryRange } from '../utils/common';
import { cleanQueryParams, removeFalseyParams } from '../utils/queryParams';
import { selectStoreCurrentCompany } from '../utils/storeSelectors';
import {
  handleApiCalls,
  handleApiFileDownloads,
  performApiCallIfCompanySubIsActiveOrCompanyAdmin,
} from './axiosInstance';

/**
 * Performs an API call to update a set of receipts with the provided Status
 *
 * @param {string[]} receiptIDs Set of IDs from receipts that should be updated
 * @param {"reject" | "approved"} status Status to update receipt with
 * @param {"approveAll" | "approvePending"} approveAction
 * @return {Promise<*>} API Result
 */
export async function updateReceiptStatus(receiptIDs = [], status, approveAction) {
  const result = await performApiCallIfCompanySubIsActiveOrCompanyAdmin(
    'patch',
    `${process.env.REACT_APP_HOST_API}trip-receipt/status`,
    removeFalseyParams({ ids: receiptIDs, status, approveAction }),
    null,
    undefined,
    [USER_ROLES.COMPANY_ADMIN],
  );
  return result.data.data;
}

/**
 * Performs an API call to `approve` a set of Receipts
 * Shorthand for `updateReceiptStatus`.
 *
 * @param {string[]} receiptIDs Set of IDs from receipts that should be updated
 * @param {"approveAll" | "approvePending"} approveAction
 * @return {Promise<*>} API Result
 */
export async function approveReceipt(receiptIDs, approveAction) {
  return updateReceiptStatus(receiptIDs, STATUS_LIST().Status.APPROVED, approveAction);
}

/**
 * Performs an API call to `reject` or `deny` a set of Receipts
 * Shorthand for `updateReceiptStatus`.
 *
 * @param {string[]} receiptIDs Set of IDs from receipts that should be updated
 * @return {Promise<*>} API Result
 */
export async function rejectReceipt(receiptIDs) {
  return updateReceiptStatus(receiptIDs, STATUS_LIST().Status.DENIED);
}

/**
 * Performs an API to update the status of trips in a receipt
 * @param {string} receiptID ID of Receipt
 * @param {string[]} tripsIds ID of Trips
 * @param {string} status Status to update
 */
export async function updateTripStatusInReceipt(receiptID, tripsIds, status) {
  const result = await performApiCallIfCompanySubIsActiveOrCompanyAdmin(
    'patch',
    `${process.env.REACT_APP_HOST_API}trip-receipt/${receiptID}/trips/status`,
    { tripsIds, status },
    null,
    undefined,
    [USER_ROLES.COMPANY_ADMIN],
  );
  return result.data.data;
}

export async function rejectTripsInReceipt(receiptID, tripsIds) {
  return updateTripStatusInReceipt(receiptID, tripsIds, STATUS_LIST().Status.DENIED);
}

export async function approveTripsInReceipt(receiptID, tripsIds) {
  return updateTripStatusInReceipt(receiptID, tripsIds, STATUS_LIST().Status.APPROVED);
}

/**
 * Performs an API call to fetch a receipt details
 *
 * @param {string} receiptID Receipt ID
 * @returns {Promise<object>}
 */
export const fetchTripReceipt = async receiptID => {
  const url = `${process.env.REACT_APP_HOST_API}trip-receipt/${receiptID}`;
  const result = await handleApiCalls('get', url);

  return result.data.data;
};

/**
 * Performs an API call to fetch the receipts of a given period for the Reimbursements page
 * when the `hideReceipts` feature is ON
 *
 * @param {object} filters
 * @returns {Promise<object>}
 */
export const fetchReceiptsOnReimburesementView = async (
  { fromDate, toDate, period, ...filters } = {},
  pageNumber,
  pageSize,
) => {
  const currentCompany = selectStoreCurrentCompany();
  const params = {
    range: formatQueryRange(pageNumber, pageSize),
    companyId: currentCompany?._id,
    ...filters,
  };

  if (period) {
    const parsedPeriod = JSON.parse(period);
    params.fromDate = parsedPeriod.fromDate;
    params.toDate = parsedPeriod.endDate;
  } else if (!!fromDate && !!toDate) {
    params.fromDate = fromDate;
    params.toDate = toDate;
  }

  const queryParams = cleanQueryParams(params);
  const url = `${process.env.REACT_APP_HOST_API}trip-receipt/reimburesement-view?${queryParams}`;
  const result = await handleApiCalls('get', url);
  return result.data;
};

/**
 * Performs an API call to create an Adjustment for a given Receipt
 *
 * @param {string} receiptID ID of Receipt that needs to be adjusted
 * @param {{ amount: number, type: string, note: string }} adjustmentData Adjustment data
 */
export const createAdjustment = async (receiptID, adjustmentData) => {
  const url = `${process.env.REACT_APP_HOST_API}trip-receipt/${receiptID}/create-adjustment`;
  const result = await performApiCallIfCompanySubIsActiveOrCompanyAdmin(
    'post',
    url,
    adjustmentData,
    null,
    undefined,
    [USER_ROLES.COMPANY_ADMIN],
  );

  return result.data.data;
};

/**
 * Performs an API call to update the Adjustment on a given Receipt
 *
 * @param {string} receiptID ID of Receipt to be updated
 * @param {{ amount: number, type: string, note: string }} adjustmentData Adjustment data
 */
export const updateAdjustment = async (receiptID, adjustmentData) => {
  return createAdjustment(receiptID, adjustmentData);
};

/**
 * Performs an API call to delete an Adjustment on a given Receipt
 *
 * @param {string} receiptId ID of Receipt to delete adjustment
 */
export const deleteAdjustment = async receiptId => {
  const url = `${process.env.REACT_APP_HOST_API}trip-receipt/${receiptId}/delete-adjustments`;
  const result = await performApiCallIfCompanySubIsActiveOrCompanyAdmin(
    'post',
    url,
    undefined,
    null,
    undefined,
    [USER_ROLES.COMPANY_ADMIN],
  );

  return result.data.data;
};

export const RECEIPT_API = Object.freeze({
  /**
   * Fetch a list of receipts
   *
   * @param {string} receiptID Receipt ID
   * @returns {Promise<object>}
   */
  fetchCompanyTripReceipts: async (filters = {}, pageNumber, pageSize) => {
    const range = formatQueryRange(pageNumber, pageSize);
    const currentCompany = selectStoreCurrentCompany();
    const queryParams = cleanQueryParams({ ...filters, range, companyId: currentCompany?._id });
    const url = `${process.env.REACT_APP_HOST_API}trip-receipt?${queryParams}`;
    const result = await handleApiCalls('get', url);

    return result.data;
  },
  /**
   * Fetch Reimbursement's Insights data
   * @param {string[]} tripReceiptsIds
   */
  fetchReimbursementInsights: async tripReceiptsIds => {
    const url = `${process.env.REACT_APP_HOST_API}trip-receipt/insight`;
    const currentCompany = selectStoreCurrentCompany();
    const result = await handleApiCalls('post', url, {
      tripReceiptsIds,
      companyId: currentCompany?._id,
    });

    return result.data;
  },
  /**
   * Export trip receipts
   * @param {object} filters
   */
  exportTripReceipt: async ({ fromDate, toDate, period, ...filters } = {}) => {
    const url = `${process.env.REACT_APP_HOST_API}trip-receipt/export`;
    const currentCompany = selectStoreCurrentCompany();
    const params = { ...filters, companyId: currentCompany?._id };

    if (period) {
      const parsedPeriod = JSON.parse(period);
      params.fromDate = parsedPeriod.fromDate;
      params.toDate = parsedPeriod.endDate;
    } else if (!!fromDate && !!toDate) {
      params.fromDate = fromDate;
      params.toDate = toDate;
    }

    return handleApiCalls('post', url, removeFalseyParams(params));
  },
  /**
   * Performs an API call a set of Receipts
   * Shorthand for `updateReceiptStatus`.
   *
   * @param {string[]} receiptIDs Set of IDs from receipts that should be updated
   * @return {Promise<*>} API Result
   */
  markReceiptAsPaid: async receiptIDs => {
    await handleApiFileDownloads(
      'patch',
      `${process.env.REACT_APP_HOST_API}trip-receipt/status/paid`,
      { tripReceiptsIds: receiptIDs },
      undefined,
      '.csv',
    );
  },

  deleteReceipt: async receiptId => {
    return handleApiCalls('delete', `${process.env.REACT_APP_HOST_API}trip-receipt/${receiptId}`);
  },
});
