import { Descriptions, Form, Space } from 'antd';
import { get, isEmpty, omit } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { handleApiErrors } from '../../../api/axiosInstance';
import { fetchCompanyPayrollAudits } from '../../../api/company';
import PAYROLL_AUDITS_API from '../../../api/payroll-audits';
import FormItem from '../../../components/Form/FormItem';
import PaymentScheduleSelect from '../../../components/PaymentScheduleSelect';
import SectionStepWrapper from '../../../components/SectionStepWrapper';
import Select from '../../../components/Select';
import SubmitCancelButtonGroup from '../../../components/SubmitCancelButtonGroup';
import PayrollFileTable from '../../../components/Table/PayrollFileTable';
import Text from '../../../components/Text';
import Toast from '../../../components/Toast';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import { formatNumberWithCurrency } from '../../../utils/numbers';
import SelectApprovedReimbursementsSection from '../PayrollFileConfigurationSection/SelectApprovedReimbursementsSection';

const PayrollFileModificationSection = ({ t, currentCompany }) => {
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(1);
  const [formValues, setFormValues] = useState({});

  const payrollFileAuditsQuery = useQuery({
    enabled: Boolean(currentCompany?._id) && current > 2,
    queryKey: ['fetchCompanyPayrollAudits', currentCompany?._id],
    queryFn: () => fetchCompanyPayrollAudits(currentCompany?._id),
  });

  const handleFormValuesChange = useCallback((_, values) => {
    if ('paymentScheduleId' in values) {
      setFormValues(omit(values, ['payrollAuditId', 'tripReceipts', 'tripReceiptsIds']));
    } else {
      setFormValues(values);
    }
  }, []);

  /**
   * This is the total number of unique user Ids present in all Trip Receipts
   * @type {number}
   */
  const totalOfUniqueUsersThatSubmittedReimbursements = useMemo(() => {
    return get(formValues, 'tripReceipts', []).reduce((acc, curr) => {
      return acc.add(get(curr, 'submittedBy._id'));
    }, new Set()).size;
  }, [formValues]);

  /**
   * This is the total amount of approved reimbursements per currency
   * @type {Map<string, number>}
   */
  const totalAmountsPerCurrency = useMemo(() => {
    return get(formValues, 'tripReceipts', []).reduce((acc, curr) => {
      const itemCurrency = get(curr, 'currency');
      const itemTotalAmount = get(curr, 'totalAmount');

      if (acc.has(itemCurrency)) {
        acc.set(itemCurrency, acc.get(itemCurrency) + itemTotalAmount);
      } else {
        acc.set(itemCurrency, itemTotalAmount);
      }
      return acc;
    }, new Map());
  }, [formValues]);

  const handleCancel = useCallback(() => {
    form.resetFields();
    setFormValues({});
  }, [form]);

  const modifyPayrollFileMutation = useMutation(PAYROLL_AUDITS_API.modifyPayrollAudits, {
    onError: error => handleApiErrors(error.response),
    onSuccess: () => {
      Toast({
        duration: 10, // seconds
        type: 'open',
        message: t('modifyPayrollAuditsSuccess'),
      });

      handleCancel();
    },
  });

  const handleSubmit = useCallback(() => {
    form.validateFields().then(() => {
      const { actionType, ...values } = formValues;
      if (actionType === 'updateExistingPayrollFileWithApprovedReimbursements') {
        modifyPayrollFileMutation.mutateAsync({
          paymentScheduleId: values.paymentScheduleId,
          payrollAuditId: values.payrollAuditId,
          tripReceiptsIds: values.tripReceiptsIds,
        });
      }
    });
  }, [form, formValues, modifyPayrollFileMutation]);

  useDidUpdateEffect(() => {
    if (isEmpty(get(formValues, 'actionType'))) {
      setCurrent(1);
    } else if (isEmpty(get(formValues, 'paymentScheduleId'))) {
      setCurrent(2);
    } else if (isEmpty(get(formValues, 'payrollAuditId'))) {
      setCurrent(3);
    } else if (!get(formValues, 'tripReceiptsIds', [])?.length) {
      setCurrent(4);
    } else {
      setCurrent(5);
    }
  }, [formValues]);

  return (
    <section>
      <Form form={form} onValuesChange={handleFormValuesChange}>
        <Space direction="vertical" size="middle" wrap={false}>
          <SectionStepWrapper
            step={1}
            title={t('selectActionType')}
            showContent={current >= 1}
            isCompleted={current > 1}
          >
            <FormItem label={t('actionType')} name="actionType">
              <Select
                fullWidth
                disabled={modifyPayrollFileMutation.isLoading}
                options={[
                  {
                    label: t('updateExistingPayrollFileWithApprovedReimbursements'),
                    value: 'updateExistingPayrollFileWithApprovedReimbursements',
                  },
                ]}
              />
            </FormItem>
          </SectionStepWrapper>

          {current > 1 && (
            <>
              <SectionStepWrapper
                step={2}
                title={t('selectPaymentSchedule')}
                showContent={current >= 2}
                isCompleted={current > 2}
              >
                <FormItem label={t('paymentSchedule')} name="paymentScheduleId">
                  <PaymentScheduleSelect t={t} disabled={modifyPayrollFileMutation.isLoading} />
                </FormItem>
              </SectionStepWrapper>

              <SectionStepWrapper
                step={3}
                title={t('selectPayrollFile')}
                showContent={current >= 3}
                isCompleted={current > 3}
              >
                <PayrollFileTable
                  t={t}
                  scroll={{ x: 400, y: 400 }}
                  loading={payrollFileAuditsQuery.isFetching || modifyPayrollFileMutation.isLoading}
                  hiddenColumns={['downloadedBy', 'date', 'timeDownloaded']}
                  dataSource={get(payrollFileAuditsQuery, 'data', [])}
                  pagination={{
                    showSizeChanger: false,
                  }}
                  onRow={record => ({
                    onClick: () => {
                      setFormValues(state => {
                        return {
                          ...state,
                          payrollAuditId: get(record, '_id'),
                        };
                      });
                    },
                  })}
                  rowSelection={{
                    type: 'radio',
                    selectedRowKeys: [get(formValues, 'payrollAuditId')],
                    onSelect: record => {
                      setFormValues(state => {
                        return {
                          ...state,
                          payrollAuditId: get(record, '_id'),
                        };
                      });
                    },
                  }}
                />
              </SectionStepWrapper>

              <SectionStepWrapper
                step={4}
                title={t('approvedReimbursements')}
                showContent={current >= 4}
                isCompleted={current > 4}
              >
                <SelectApprovedReimbursementsSection
                  t={t}
                  disabled={modifyPayrollFileMutation.isLoading}
                  companyId={currentCompany?._id}
                  paymentScheduleId={get(formValues, 'paymentScheduleId')}
                  selectedRows={get(formValues, 'tripReceiptsIds', [])}
                  onSelect={(selectedKeys, selectedRows) => {
                    setFormValues(state => {
                      return {
                        ...state,
                        tripReceipts: selectedRows,
                        tripReceiptsIds: selectedKeys,
                      };
                    });
                  }}
                />
              </SectionStepWrapper>

              <SectionStepWrapper
                step={5}
                showContent={current === 5}
                isCompleted={current === 5}
                title={t('summary')}
              >
                <Text>{t('selectedItemsWillBe')}:</Text>

                <ul>
                  <li>
                    <Text>{t('markedAsPaid')}</Text>
                  </li>
                  <li>
                    <Text>{t('newPayrollFileGenerated')}</Text>
                  </li>
                  <li>
                    <Text>{t('previousPayrollFileReplaced')}</Text>
                  </li>
                </ul>

                <Descriptions size="small" column={1} layout="horizontal">
                  <Descriptions.Item label={<Text variant="b">{t('totalUsersAffected')}</Text>}>
                    {totalOfUniqueUsersThatSubmittedReimbursements}
                  </Descriptions.Item>

                  <Descriptions.Item
                    label={<Text variant="b">{t('totalNumberReceiptsReimbursements')}</Text>}
                  >
                    {get(formValues, 'tripReceipts', []).length}
                  </Descriptions.Item>

                  <Descriptions.Item label={<Text variant="b">{t('totalAmount')}</Text>}>
                    <Space direction="vertical" size="small" wrap={false}>
                      {Array.from(totalAmountsPerCurrency.entries()).map(([currency, amount]) => (
                        <Text key={currency}>{formatNumberWithCurrency(amount, currency)}</Text>
                      ))}
                    </Space>
                  </Descriptions.Item>
                </Descriptions>
              </SectionStepWrapper>
            </>
          )}

          <SubmitCancelButtonGroup
            hideCancel={current < 2}
            disabled={modifyPayrollFileMutation.isLoading}
            loading={modifyPayrollFileMutation.isLoading}
            submitDisabled={current !== 5}
            submitText={t('confirmUpdate')}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
          />
        </Space>
      </Form>
    </section>
  );
};

export default PayrollFileModificationSection;
