import { formatQueryRange } from '../utils/common';
import { cleanQueryParams } from '../utils/queryParams';
import { handleApiCalls } from './axiosInstance';
import BaseAPI from './baseAPI';

class PayrollFileConfigurationAPI extends BaseAPI {
  constructor() {
    super({ suffix: 'payroll-file-configurations' });
  }

  /**
   * @param {string} companyId ID of company
   */
  async fetchPayrollFileConfiguration(companyId, pageNumber, pageSize) {
    const queryParams = cleanQueryParams({
      companyId,
      range: formatQueryRange(pageNumber, pageSize),
    });
    const result = await handleApiCalls('get', `${this.baseUrl}?${queryParams}`);

    return result.data;
  }

  async fetchPayrollFileConfigurationDetails(fileId) {
    const result = await handleApiCalls('get', `${this.baseUrl}/${fileId}`);

    return result.data;
  }
}

export default PayrollFileConfigurationAPI;
