import { useEffect } from 'react';

import { SENTRY_ANALYTICS } from '../utils/sentry';

/**
 * React hook that manages Sentry session replay recording lifecycle.
 * Automatically starts recording on mount and stops on unmount.
 * Used to capture user interactions and reproduce issues.
 *
 * @returns {{ 'data-sentry-unmask': boolean }} Props object that can be spread onto components
 */
const useSentryReplay = () => {
  useEffect(() => {
    SENTRY_ANALYTICS.startReplay();

    return () => {
      SENTRY_ANALYTICS.stopReplay();
    };
  }, []);

  return { 'data-sentry-unmask': true };
};

export default useSentryReplay;
