import { DeleteOutlined, RightOutlined } from '@ant-design/icons';
import { Col, Row, Space } from 'antd';
import { sortBy } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';

import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import Box from '../Box';
import Text from '../Text';
import classNames from './styles.module.scss';

/**
 * The Transfer component allows users to transfer items between two lists.
 * It is designed to be used for selecting items from a source list and moving them to a target list.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.disabled - Whether the component is disabled.
 * @param {number} props.listHeight - The height of the list.
 * @param {Array} props.dataSource - The data source for the lists.
 * @param {Array} props.initialSelectedItems - The initially selected items.
 * @param {string} props.leftListTitle - The title for the left list.
 * @param {string} props.rightListTitle - The title for the right list.
 * @param {Function} props.onChange - The callback function for when the selected items change.
 * @param {Array} props.selectableValues - The values that are selectable.
 */
const Transfer = ({
  disabled,
  listHeight,
  dataSource,
  initialSelectedItems,
  leftListTitle,
  rightListTitle,
  onChange,
  selectableValues,
}) => {
  const [selectedItems, setSelectedItems] = useState(initialSelectedItems);

  /**
   * Computes the left list based on the selected items and selectable values.
   *
   * @returns {Array} - The items for the left list.
   */
  const leftList = useMemo(() => {
    const list = dataSource.filter(item => !selectedItems.includes(item.value));
    if (Array.isArray(selectableValues)) {
      return list.filter(item => selectableValues.includes(item.value));
    }

    return list;
  }, [selectedItems, dataSource, selectableValues]);

  /**
   * Computes the right list based on the selected items.
   *
   * @returns {Array} - The items for the right list.
   */
  const rightList = useMemo(() => dataSource.filter(item => selectedItems.includes(item.value)), [
    selectedItems,
    dataSource,
  ]);

  /**
   * Selects an item and updates the state.
   *
   * @param {Object} item - The item to select.
   */
  const selectItem = useCallback(
    item => {
      if (typeof onChange === 'function' && !disabled) {
        setSelectedItems(prevItems => {
          return sortBy([...prevItems, item.value], 'label');
        });
      }
    },
    [onChange, disabled],
  );

  /**
   * Unselects an item and updates the state.
   *
   * @param {Object} item - The item to unselect.
   */
  const unselectItem = useCallback(
    item => {
      if (typeof onChange === 'function' && !disabled) {
        setSelectedItems(prevItems => {
          return sortBy(
            prevItems.filter(selectedItem => selectedItem !== item.value),
            'label',
          );
        });
      }
    },
    [onChange, disabled],
  );

  /**
   * Updates the parent component with the selected items when they change.
   */
  useDidUpdateEffect(() => {
    if (typeof onChange === 'function' && selectedItems) {
      onChange(selectedItems);
    }
  }, [selectedItems]);

  return (
    <Row align="middle" gutter={[5, 5]} wrap={false}>
      <Col flex="50%" style={{ height: listHeight }}>
        <Box noShadow padding="0px" className={classNames.listBox}>
          {leftListTitle && (
            <Text variant="b" size="sm" className={classNames.listTitle}>
              {leftListTitle}
            </Text>
          )}

          <div className={classNames.listContent}>
            {leftList.map(item => (
              <Space
                wrap={false}
                direction="horizontal"
                key={item.value}
                onClick={() => selectItem(item)}
                className={classNames.listItem}
              >
                <Text size="sm">{item.label}</Text>

                <RightOutlined />
              </Space>
            ))}
          </div>
        </Box>
      </Col>

      <Col flex="50%" style={{ height: listHeight }}>
        <Box noShadow padding="0px" className={classNames.listBox}>
          {rightListTitle && (
            <Text variant="b" size="sm" className={classNames.listTitle}>
              {rightListTitle}
            </Text>
          )}

          <div className={classNames.listContent}>
            {rightList.map(item => (
              <div
                key={item.value}
                onClick={() => unselectItem(item)}
                className={classNames.listItem}
              >
                <Text size="sm">{item.label}</Text>

                <DeleteOutlined />
              </div>
            ))}
          </div>
        </Box>
      </Col>
    </Row>
  );
};

Transfer.defaultProps = {
  listHeight: 250,
  initialItems: [],
  initialSelectedItems: [],
};

export default Transfer;
