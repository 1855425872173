import React, { useMemo } from 'react';
import { useQuery } from 'react-query';

import { CARS_API } from '../../api/cars';
import Select from '.';

const VehicleMakeSelect = props => {
  const { t, year, disabled, showOtherOption, upperCaseValue, ...rest } = props;

  const vehicleMakesQuery = useQuery(['fetchCarMakes', year], {
    enabled: !!year,
    staleTime: Infinity,
    queryFn: () => CARS_API.fetchCarMakes(year),
  });

  const vehicleMakeOptions = useMemo(() => {
    let options = [];

    if (Array.isArray(vehicleMakesQuery.data)) {
      options = [
        ...vehicleMakesQuery.data.map(make => ({
          label: make,
          value: upperCaseValue ? make?.toUpperCase() : make,
        })),
      ];
    }

    if (showOtherOption) {
      options.push({ label: 'Other', value: 'other' });
    }

    return options;
  }, [vehicleMakesQuery.data, upperCaseValue, showOtherOption]);

  return (
    <Select
      {...rest}
      showSearch
      disabled={disabled || !year || vehicleMakesQuery.isFetching || !vehicleMakeOptions?.length}
      loading={vehicleMakesQuery.isFetching}
      placeholder={t('selectMake')}
      options={vehicleMakeOptions}
    />
  );
};

VehicleMakeSelect.defaultProps = {
  showOtherOption: true,
  upperCaseValue: true,
};

export default VehicleMakeSelect;
