import { AutoComplete } from 'antd';
import { filter, throttle, upperCase } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { CARS_API } from '../../api/cars';
import TextInput from '../TextInput';

const VehicleModelAutoComplete = props => {
  const { t, year, make, value, disabled, onChange, ...rest } = props;

  const [internalValue, setInternalValue] = useState(value);

  const vehicleModelsQuery = useQuery(['fetchCarModels', year, make], {
    enabled: !!year && !!make && make !== 'other',
    staleTime: Infinity,
    queryFn: () => CARS_API.fetchCarModels(year, make),
  });

  const vehicleMakeOptions = useMemo(() => {
    let options = [];

    if (Array.isArray(vehicleModelsQuery.data)) {
      options = [
        ...vehicleModelsQuery.data.map(make => ({
          label: make,
          value: make,
        })),
      ];
    }

    return internalValue
      ? filter(options, option => new RegExp(internalValue, 'gi').test(upperCase(option.value)))
      : options;
  }, [vehicleModelsQuery.data, internalValue]);

  const handleChange = useCallback(
    throttle(
      value => {
        if (typeof onChange === 'function') {
          onChange(value);
        }

        setInternalValue(value);
      },
      800,
      { trailing: true },
    ),
    [onChange],
  );

  return (
    <AutoComplete
      {...rest}
      value={value}
      options={vehicleMakeOptions}
      disabled={disabled || !year || vehicleModelsQuery.isFetching}
      loading={vehicleModelsQuery.isFetching}
      onChange={handleChange}
    >
      <TextInput
        value={value}
        placeholder={t('selectModel')}
        disabled={disabled || !year || vehicleModelsQuery.isFetching}
      />
    </AutoComplete>
  );
};

export default VehicleModelAutoComplete;
