import { mapValues } from 'lodash';

import { STATUS_LIST } from '../enum';
import { formatQueryRange } from '../utils/common';
import { cleanQueryParams } from '../utils/queryParams';
import { handleApiCalls, handleApiFileDownloads } from './axiosInstance';

export const FAVR_COMPLIANCES_API = Object.freeze({
  /**
   * Get FAVR compliance
   * @param {string} id
   */
  fetchFavrComplianceDetails: async id => {
    const url = `${process.env.REACT_APP_HOST_API}favr-compliances/${id}`;
    const response = await handleApiCalls('get', url);
    const data = response.data;
    data.status =
      data.status === STATUS_LIST().Status.DENIED
        ? STATUS_LIST().Status.NON_COMPLIANT
        : data.status;

    return data;
  },
  /**
   * Update FAVR compliance
   * @param {object} params
   * @param {object.boolean} params.insuranceName
   * @param {object.boolean} params.insuranceHomeAddress
   * @param {object.boolean} params.insuranceVehicle
   * @param {object.boolean} params.insurancePolicyStartDate
   * @param {object.boolean} params.insurancePolicyEndDate
   * @param {object.string} params.insurancePolicyStartDateValue
   * @param {object.string} params.insurancePolicyEndDateValue
   * @param {object.boolean} params.insuranceCompany
   * @param {object.boolean} params.bodilyInjuryToOthers
   * @param {object.boolean} params.damageToProperty
   * @param {object.boolean} params.personalInjuryProtection
   * @param {object.boolean} params.bodilyInjuryByUninsuredAuto
   * @param {object.boolean} params.optionalBodilyInjuryToOthers
   * @param {object.boolean} params.bodilyInjuryByUnderinsuredAuto
   * @param {object.boolean} params.medicalPayments
   * @param {object.boolean} params.collision
   * @param {object.boolean} params.comprehensive
   * @param {object.number} params.bodilyInjuryToOthersValue
   */
  updateFavrCompliance: async (id, params) => {
    const url = `${process.env.REACT_APP_HOST_API}favr-compliances/${id}`;
    const response = await handleApiCalls('put', url, params);

    return response.data;
  },
  /**
   * Overrides the FAVR compliance status for a given compliance ID.
   *
   * @param {string} id - The unique identifier of the FAVR compliance.
   * @param {string} overrideReason - The reason for overriding the compliance status.
   * @param {"approved" | "denied"} status - The new compliance status to set.
   * @returns {Promise<object>} The updated compliance data returned from the API.
   */
  overrideFavrComplianceStatus: async (id, overrideReason, status) => {
    const url = `${process.env.REACT_APP_HOST_API}favr-compliances/${id}/status/override`;
    const response = await handleApiCalls('patch', url, { overrideReason, status });

    return response.data;
  },
  /**
   * Override FAVR compliance status
   * @param {string} id
   * @param {string} reason
   */
  overrideMeasureOneComplianceStatus: async (id, overrideReason, complianceComponents) => {
    const url = `${process.env.REACT_APP_HOST_API}favr-compliances/${id}/components/override`;
    const response = await handleApiCalls('patch', url, { overrideReason, complianceComponents });

    return response.data;
  },
  /**
   * @param {string} userID
   * @returns {Promise<{ insuranceComplianceStatus: string, driverLicenseComplianceStatus: string }>}
   */
  fetchUserFavrComplianceStatuses: async userID => {
    const url = `${process.env.REACT_APP_HOST_API}user/${userID}/favr-compliances/status`;
    const response = await handleApiCalls('get', url);

    return mapValues(response.data, (value, key) => {
      if (/status/gi.test(key)) {
        return value === STATUS_LIST().Status.DENIED ? STATUS_LIST().Status.NON_COMPLIANT : value;
      }
      return value;
    });
  },

  /**
   * @param {string} userID
   */
  fetchUserFavrComplianceDetails: async userID => {
    const url = `${process.env.REACT_APP_HOST_API}user/${userID}/favr-compliances`;
    const response = await handleApiCalls('get', url);

    return response.data;
  },

  /**
   * @param {string} userID
   * @param {number} pageNumber
   * @param {number} pageSize
   */
  fetchUserFavrComplianceHistory: async (userID, pageNumber, pageSize, params = {}) => {
    const range = formatQueryRange(pageNumber, pageSize);
    const queryParams = cleanQueryParams({ range, ...params });

    const url = `${process.env.REACT_APP_HOST_API}user/${userID}/favr-compliance-audits?${queryParams}`;
    const response = await handleApiCalls('get', url);

    return response.data;
  },

  /**
   * @param {object} filters
   * @param {?number} pageNumber
   * @param {?number} pageSize
   * @returns {object[]}
   */
  fetchFavrCompliances: async (filters = {}, pageNumber, pageSize) => {
    const range = formatQueryRange(pageNumber, pageSize);
    const queryParams = cleanQueryParams({ ...filters, range });

    const url = `${process.env.REACT_APP_HOST_API}favr-compliances?${queryParams}`;
    const response = await handleApiCalls('get', url);

    return {
      totalCount: response.data.totalCount,
      documents: response.data.documents.map(item => {
        item.status =
          item.status === STATUS_LIST().Status.DENIED
            ? STATUS_LIST().Status.NON_COMPLIANT
            : item.status;

        return item;
      }),
    };
  },

  /**
   * @param {string} complianceId
   * @param {?number} pageNumber
   * @param {?number} pageSize
   * @returns {object[]}
   */
  fetchFavrComplianceNotes: async complianceId => {
    const url = `${process.env.REACT_APP_HOST_API}favr-compliances/${complianceId}/notes`;
    const response = await handleApiCalls('get', url);

    return response.data;
  },

  /**
   * @param {string} id
   * @param {string} note
   * @returns {object[]}
   */
  addNoteToFavrCompliance: async (id, note, isInternal) => {
    const url = `${process.env.REACT_APP_HOST_API}favr-compliances/note`;
    const response = await handleApiCalls('post', url, { id, note, isInternal });

    return response.data;
  },

  /**
   * @param {string} id
   * @param {"pending" | "approved" | "denied"} status
   */
  downloadFavrComplianceFiles: async id => {
    const url = `${process.env.REACT_APP_HOST_API}favr-compliances/${id}/download-files`;
    await handleApiFileDownloads('get', url, {});
  },

  downloadFavrComplianceImages: async id => {},

  /**
   * @param {"insurance" | "business-vehicle"} type
   */
  confirmComplianceInformation: async type => {
    const url = `${process.env.REACT_APP_HOST_API}favr-compliances/confirm-informations`;
    await handleApiCalls('patch', url, { type });
  },
});
