import { Col, Row } from 'antd';
import { get } from 'lodash';
import React from 'react';

import { renderCheckmarkFromBoolean } from '../../utils/tables';
import Text from '../Text';

const insuranceComplianceItems = [
  'bodilyInjuryByUnderinsuredAuto',
  'bodilyInjuryByUninsuredAuto',
  'bodilyInjuryToOthers',
  'collision',
  'comprehensive',
  'damageToProperty',
  'insuranceCompany',
  'insuranceHomeAddress',
  'insuranceName',
  'insurancePolicyEndDate',
  'insurancePolicyStartDate',
  'insuranceVehicle',
  'medicalPayments',
  'optionalBodilyInjuryToOthers',
  'personalInjuryProtection',
];

const InsuranceComplianceItemsList = props => {
  const { t, complianceComponents } = props;

  return (
    <Row gutter={[0, 10]}>
      {(complianceComponents && typeof complianceComponents === 'object'
        ? Object.keys(complianceComponents)
        : insuranceComplianceItems
      ).map(key => {
        return (
          <Col key={key} flex="100%">
            <Row align="middle" gutter={5} wrap={false}>
              <Col>{renderCheckmarkFromBoolean(get(complianceComponents, key))}</Col>
              <Col flex={1}>
                <Text size="sm">{t(key === 'insuranceName' ? 'insuredName' : key)}</Text>
              </Col>
            </Row>
          </Col>
        );
      })}
    </Row>
  );
};

export default InsuranceComplianceItemsList;
