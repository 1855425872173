import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Row, Space } from 'antd';
import { map, omit } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import { replaceValueInArrayByID } from '../../../utils/common';
import Button from '../../Button';
import FormItem from '../../Form/FormItem';
import FadedText from '../../Text/FadedText';
import TextInput from '../../TextInput';

/**
 * Initial field values for the form items.
 * @type {Array}
 */
const initialFieldValues = [{ id: +new Date(), name: '', value: '' }];

/**
 * Adds unique IDs to each field in the array.
 * @param {Array} fields - The array of fields to add IDs to.
 * @returns {Array} - The array of fields with unique IDs.
 */
const addIdsToFields = fields => {
  return fields.map((field, i) => {
    field.id = +new Date() + i;
    return field;
  });
};

/**
 * DynamicCustomFieldFormItems component.
 *
 * This component dynamically renders form items for custom fields.
 * It allows adding, removing, and updating fields.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.t - The translation function.
 * @param {Array} props.dataSource - The initial data source for the fields.
 * @param {Boolean} props.disabled - Whether the component is disabled.
 * @param {Number} props.maxItems - The maximum number of items allowed.
 * @param {String} props.extra - Extra text to display.
 * @param {Object} props.extraAction - An object containing a label and a callback for an extra action.
 * @param {Function} props.onChange - The callback function for when the fields change.
 */
const DynamicCustomFieldFormItems = ({
  t,
  dataSource,
  disabled,
  maxItems,
  extra,
  extraAction,
  onChange,
}) => {
  /**
   * State for the field items.
   * @type {Array}
   */
  const [fieldItems, setFieldItems] = useState([]);

  /**
   * Handles changes to the field items and notifies the parent component.
   * @param {Array} updatedArray - The updated array of field items.
   */
  const handleOnChange = useCallback(
    updatedArray => {
      if (typeof onChange === 'function') {
        const array = map(updatedArray, item => omit(item, 'id'));
        onChange(array);
      }
    },
    [onChange],
  );

  /**
   * Adds a new field item.
   */
  const addField = useCallback(() => {
    setFieldItems(fields => {
      const updatedArray = [...fields, { id: +new Date(), name: '', value: '' }];
      return updatedArray;
    });
  }, []);

  /**
   * Removes a field item by index.
   * @param {Number} index - The index of the field item to remove.
   */
  const removeField = useCallback(
    index => {
      setFieldItems(fields => {
        const updatedArray = [...fields];
        updatedArray.splice(index, 1);
        handleOnChange(updatedArray);
        if (!updatedArray.length) {
          initialFieldValues[0].id = +new Date();
          return [...initialFieldValues];
        }
        return updatedArray;
      });
    },
    [handleOnChange],
  );

  /**
   * Updates a field item by ID.
   * @param {Number} id - The ID of the field item to update.
   * @param {Object} values - The values to update the field item with.
   */
  const updatedField = useCallback(
    (id, values = {}) => {
      setFieldItems(fields => {
        const updatedArray = replaceValueInArrayByID(fields, { ...values, id }, 'id');
        handleOnChange(updatedArray);
        return updatedArray;
      });
    },
    [handleOnChange],
  );

  /**
   * Ensures at least one field item is present.
   */
  useEffect(() => {
    if (!fieldItems?.length) {
      addField();
    }
  }, [fieldItems, addField]);

  /**
   * Initializes field items from the data source if present.
   */
  useEffect(() => {
    if (!!dataSource?.length && !fieldItems?.length) {
      setFieldItems(addIdsToFields(dataSource));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {fieldItems.map(({ id, name, value }, index) => {
        return (
          <Row key={id} gutter={[16, 16]} style={{ alignItems: 'baseline' }} wrap={false}>
            <Col flex={1}>
              <FormItem>
                <TextInput
                  defaultValue={name}
                  placeholder={t('fieldName')}
                  onChange={e => updatedField(id, { name: e.target.value })}
                  disabled={disabled}
                />
              </FormItem>
            </Col>
            <Col flex={1}>
              <FormItem>
                <TextInput
                  placeholder={t('value')}
                  defaultValue={value}
                  onChange={e => updatedField(id, { value: e.target.value })}
                  disabled={disabled}
                />
              </FormItem>
            </Col>

            {(name || value || fieldItems.length > 1) && (
              <Col>
                <DeleteOutlined onClick={() => removeField(index)} />
              </Col>
            )}
          </Row>
        );
      })}

      <Space direction="vertical" size="small">
        {extra && <FadedText size="sm">{extra}</FadedText>}

        <Row gutter={[16, 16]} wrap={false}>
          {(!maxItems || fieldItems?.length < maxItems) && (
            <Col flex={1}>
              <Button
                block
                size="xs"
                type="dashed"
                onClick={() => addField()}
                icon={<PlusOutlined />}
                disabled={disabled}
              >
                {t('addField')}
              </Button>
            </Col>
          )}

          {extraAction && (
            <Col>
              <Button
                size="xs"
                onClick={() => extraAction.callback(fieldItems)}
                loading={disabled}
                disabled={disabled}
              >
                {extraAction.label}
              </Button>
            </Col>
          )}
        </Row>
      </Space>
    </div>
  );
};

DynamicCustomFieldFormItems.defaultProps = {
  maxItems: Number.MAX_SAFE_INTEGER,
  extraAction: null,
};

export default DynamicCustomFieldFormItems;
