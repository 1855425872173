import { AutoComplete } from 'antd';
import { filter, throttle, upperCase } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { CARS_API } from '../../api/cars';
import TextInput from '../TextInput';

const VehicleMakeAutoComplete = props => {
  const { t, year, disabled, onChange, value, ...rest } = props;

  const [internalValue, setInternalValue] = useState(value);

  const vehicleMakesQuery = useQuery(['fetchCarMakes', year], {
    enabled: year?.length > 3,
    staleTime: Infinity,
    queryFn: () => CARS_API.fetchCarMakes(year),
  });

  const vehicleMakeOptions = useMemo(() => {
    let options = [];

    if (Array.isArray(vehicleMakesQuery.data)) {
      options = [
        ...vehicleMakesQuery.data.map(make => ({
          label: make,
          value: make,
        })),
      ];
    }

    return internalValue
      ? filter(options, option => new RegExp(internalValue, 'gi').test(upperCase(option.value)))
      : options;
  }, [vehicleMakesQuery.data, internalValue]);

  const handleChange = useCallback(
    throttle(
      value => {
        if (typeof onChange === 'function') {
          onChange(value);
        }

        setInternalValue(value);
      },
      800,
      { trailing: true },
    ),
    [onChange],
  );

  return (
    <AutoComplete
      {...rest}
      value={value}
      options={vehicleMakeOptions}
      disabled={disabled || !year || vehicleMakesQuery.isFetching}
      loading={vehicleMakesQuery.isFetching}
      onChange={handleChange}
    >
      <TextInput
        value={value}
        placeholder={t('selectMake')}
        disabled={disabled || !year || vehicleMakesQuery.isFetching}
      />
    </AutoComplete>
  );
};

export default VehicleMakeAutoComplete;
