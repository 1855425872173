import { Form } from 'antd';
import React from 'react';

import { ConfirmModal } from '../../../components';
import Checkbox from '../../../components/Checkbox';
import FormItem from '../../../components/Form/FormItem';
import Radio from '../../../components/Radio';
import Text from '../../../components/Text';
import useModal from '../../../hooks/useModal';
import { ERROR_MESSAGE } from '../../../utils/constants';
import { formatCurrencyToCompanyLocale } from '../../../utils/numbers';

const VehicleFavrAdditionalInfoForm = props => {
  const {
    t,
    isSubmitting,
    formInstance,
    isRequired,
    onFieldChange,
    msrpAndSalesTax,
    rateCountry,
  } = props;

  const [
    msrpConfirmationModalIsVisible,
    openMsrpConfirmationModal,
    closeMsrpConfirmationModal,
  ] = useModal();

  return (
    <Form form={formInstance} labelCol={{ span: 24 }}>
      <FormItem
        name="msrpConfirmed"
        label={t('msrpLowerOrEqual', {
          price: formatCurrencyToCompanyLocale(msrpAndSalesTax || 0),
        })}
        rules={[{ required: isRequired, message: ERROR_MESSAGE().BLANK_FIELD }]}
      >
        <Radio.Group
          name="msrpConfirmed"
          disabled={isSubmitting}
          onChange={e => {
            onFieldChange({ msrpConfirmed: e.target.value });
            if (!e.target.value) {
              openMsrpConfirmationModal();
            }
          }}
        >
          <Radio value={true}>{t('yes')}</Radio>
          <Radio value={false}>{t('no')}</Radio>
        </Radio.Group>
      </FormItem>

      {!['CA'].includes(rateCountry) && (
        <FormItem
          name="favrDepreciationType"
          label={t('claimedOnOfTheFollowingDepreciationMethods')}
          rules={[{ required: isRequired, message: ERROR_MESSAGE().BLANK_FIELD }]}
        >
          <Radio.Group
            name="favrDepreciationType"
            disabled={isSubmitting}
            onChange={e => onFieldChange({ favrDepreciationType: e.target.value })}
          >
            <Radio value="STRAIGHT_LINE">{t('straightLineDepreciation')}</Radio>
            <Radio value="ADDNL_DEPR">{t('additionalDepreciationInThe1stYear')}</Radio>
            <Radio value="ACRS">{t('ACRS')}</Radio>
            <Radio value="MACRS">{t('MACRS')}</Radio>
            <Radio value="NONE">
              {t('noneOfTheAbove')} ({t('thisIsTheMostCommonAnswer')})
            </Radio>
          </Radio.Group>
        </FormItem>
      )}

      <FormItem
        name="electronicSignatureConfirmed"
        valuePropName="checked"
        label={t('vehicleInfoElectronicSignature')}
        rules={[{ required: isRequired, message: ERROR_MESSAGE().BLANK_FIELD }]}
      >
        <Checkbox
          disabled={isSubmitting}
          onChange={e => onFieldChange({ electronicSignatureConfirmed: e.target.checked })}
        >
          {t('iAgree')}
        </Checkbox>
      </FormItem>

      <ConfirmModal
        okText={t('yes')}
        closable={false}
        maskClosable={false}
        cancelText={t('no')}
        visible={msrpConfirmationModalIsVisible}
        onOk={closeMsrpConfirmationModal}
        onCancel={closeMsrpConfirmationModal}
        message={
          <div>
            <Text size="sm">
              {t('sureVehicleMSRPWhenNewWasLess', {
                amount: formatCurrencyToCompanyLocale(msrpAndSalesTax || 0),
              })}
            </Text>
            <br />
            <Text size="sm">{t('ifNoVehicleIsNotInCompliance')}</Text>
          </div>
        }
      />
    </Form>
  );
};

export default VehicleFavrAdditionalInfoForm;
