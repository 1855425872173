import moment from 'moment-timezone';

export const convertMillisecondsIntoReadableTime = milliseconds => {
  const timestamp = moment.duration(milliseconds, 'milliseconds');
  const hours = timestamp.hours();
  const minutes = timestamp.minutes();

  return [hours > 0 ? `${hours}h` : undefined, `${minutes}min`].filter(Boolean).join(' ');
};

export const getMomentDateWithoutTimezone = date => {
  if (!date) return moment();
  const year = moment.parseZone(date).year();
  const month = moment.parseZone(date).month() + 1;
  const day = moment.parseZone(date).date();
  return moment(`${year}-${month}-${day}`, 'YYYY-MM-DD');
};

export const getTimeDifferenceBetweenTwoDates = (date1, date2) => {
  return convertMillisecondsIntoReadableTime(date2.diff(date1, 'ms'));
};

export const formatDMLMonthYearPayload = date => {
  return `${date.month() + 1}`.padStart(2, '0') + '/' + date.year();
};
