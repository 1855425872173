import classnames from 'classnames';
import React from 'react';

import Box from '.';
import classNames from './style.module.scss';

const StickyBox = props => {
  const { children, className, placement, placementOffset, ...rest } = props;

  return (
    <Box
      {...rest}
      style={{ '--placementOffset': placementOffset }}
      className={classnames(className, classNames.stickyBox, classNames[placement])}
    >
      {children}
    </Box>
  );
};

StickyBox.defaultProps = {
  placement: 'bottom',
  placementOffset: '0px',
};

export default StickyBox;
