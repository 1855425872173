import { formatQueryRange } from '../utils/common';
import { cleanQueryParams } from '../utils/queryParams';
import { handleApiCalls, handleApiFileDownloads } from './axiosInstance';
import BaseAPI from './baseAPI';

/**
 * API class for handling FAVR tax report operations
 * @extends BaseAPI
 */
class FAVRTaxReportsAPI extends BaseAPI {
  constructor() {
    super({ suffix: 'favr-tax-reports' });
  }

  /**
   * Fetches FAVR tax reports with pagination and sorting
   * @param {string} companyId - ID of the company
   * @param {number} year - Tax year to fetch reports for
   * @param {number} pageNumber - Page number for pagination
   * @param {number} pageSize - Number of items per page
   * @param {string} sort - Sort parameter
   * @returns {Promise<Object>} Tax report data
   */
  async fetchFavrTaxReports(companyId, year, pageNumber, pageSize, sort) {
    const queryParams = cleanQueryParams({
      companyId,
      year,
      sort,
      range: formatQueryRange(pageNumber, pageSize),
    });
    const result = await handleApiCalls('get', `${this.baseUrl}?${queryParams}`);

    return result.data;
  }

  /**
   * Exports FAVR tax reports to a file
   * @param {string} companyId - ID of the company
   * @param {number} year - Tax year to export reports for
   * @param {number} pageNumber - Page number for pagination
   * @param {number} pageSize - Number of items per page
   * @param {string} sort - Sort parameter
   * @returns {Promise<void>}
   */
  async exportFavrTaxReports(companyId, year, pageNumber, pageSize, sort) {
    const queryParams = cleanQueryParams({
      companyId,
      year,
      sort,
      range: formatQueryRange(pageNumber, pageSize),
    });
    await handleApiFileDownloads('get', `${this.baseUrl}/export?${queryParams}`);
  }

  /**
   * Exports a tax report in CSV format
   * @param {string} companyId - ID of the company
   * @param {"annual" | "monthly"} type - Type of tax report to export
   * @returns {Promise<void>}
   */
  async exportTaxReport(companyId, type) {
    await handleApiFileDownloads('get', `${this.baseUrl}/${companyId}/csv`, {
      type,
    });
  }
}

/**
 * Instance of FAVRTaxReportsAPI class that handles FAVR Tax Report related API calls
 * @type {FAVRTaxReportsAPI}
 */
const FAVR_TAX_REPORTS_API = new FAVRTaxReportsAPI();

export default FAVR_TAX_REPORTS_API;
