import { handleApiCalls } from './axiosInstance';

const PAYROLL_AUDITS_API = Object.freeze({
  /**
   * Modifies payroll audits by adding trip receipts to a specified payroll audit.
   *
   * @param {Object} params - The parameters for modifying payroll audits.
   * @param {string} params.payrollAuditId - The ID of the payroll audit to modify.
   * @param {string} params.paymentScheduleId - The ID of the payment schedule associated with the payroll audit.
   * @param {Array<string>} params.tripReceiptsIds - An array of trip receipt IDs to be added to the payroll audit.
   * @returns {Promise<Object>} The response data from the API call.
   */
  modifyPayrollAudits: async ({ payrollAuditId, paymentScheduleId, tripReceiptsIds }) => {
    const url = `${process.env.REACT_APP_HOST_API}payroll-audits/modify`;
    const response = await handleApiCalls('post', url, {
      action: 'add',
      payrollAuditId,
      paymentScheduleId,
      tripReceiptsIds,
    });
    return response.data;
  },
});

export default PAYROLL_AUDITS_API;
