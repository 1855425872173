import { Alert as AntAlert } from 'antd';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import classNames from './styles.module.scss';

const Alert = props => {
  const { type, closable, showIcon, message, title, center, className, fullWidth, ...rest } = props;

  return (
    <AntAlert
      {...rest}
      type={type}
      message={title}
      description={message}
      showIcon={showIcon}
      closable={closable}
      className={classnames(
        classNames.alert,
        { [classNames.centerAlert]: center, [classNames.fullWidth]: fullWidth },
        className,
      )}
    />
  );
};

Alert.propTypes = {
  type: PropTypes.oneOf(['success', 'info', 'warning', 'error']).isRequired,
  message: PropTypes.node,
  title: PropTypes.node.isRequired,
  closable: PropTypes.bool,
  showIcon: PropTypes.bool,
  icon: PropTypes.node,
  fullWidth: PropTypes.bool,
};

Alert.defaultProps = {
  closable: true,
  showIcon: true,
  fullWidth: false,
};

export default Alert;
