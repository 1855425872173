import { Col, Row } from 'antd';
import React from 'react';
import { useMutation } from 'react-query';

import FAVR_TAX_REPORTS_API from '../../api/favr-tax-reports';
import DownloadIcon from '../../components/DownloadIcon';
import FormItem from '../../components/Form/FormItem';
import Select from '../../components/Select';
import TaxReportAnnualTable from '../../components/Table/TaxReportAnnualTable';
import TaxReportMonthlyTable from '../../components/Table/TaxReportMonthlyTable';
import Text from '../../components/Text';
import Toast from '../../components/Toast';

const UserTaxReportPageContent = props => {
  const { t, taxReportData, taxableYears, selectedtaxYear, onYearChange } = props;

  const handleMonthlyReportDownload = useMutation(
    () => FAVR_TAX_REPORTS_API.exportTaxReport(taxReportData?.favrTaxReportId, 'monthly'),
    {
      onError: () => {
        Toast({
          type: 'error',
          message: t('taxReportExportCsvError'),
        });
      },
    },
  );

  const handleAnnualReportDownload = useMutation(
    () => FAVR_TAX_REPORTS_API.exportTaxReport(taxReportData?.favrTaxReportId, 'annual'),
    {
      onError: () => {
        Toast({
          type: 'error',
          message: t('taxReportExportCsvError'),
        });
      },
    },
  );

  const isAnnualDownloadDisabled = !(taxReportData?.taxReport?.annualMileageTaxReportData || [])
    .length;

  const isMonthlyDownloadDisabled = !(taxReportData?.taxReport?.monthlyMileageTaxReportData || [])
    .length;

  return (
    <>
      <Row>
        <FormItem label={t('taxYear')}>
          <Select
            style={{ minWidth: 200 }}
            value={selectedtaxYear}
            options={taxableYears.map(year => ({
              label: year === new Date().getFullYear() ? t('currentYearSuffix', { year }) : year,
              value: year,
            }))}
            onChange={onYearChange}
          />
        </FormItem>
      </Row>

      <Row gutter={16} wrap={false} justify="space-between" align="middle">
        <Col flex={1}>
          <Text variant="h4">{t('monthlyMileageBased')}</Text>
        </Col>

        <Col>
          <Row gutter={16} wrap={false} align="middle">
            <Col>
              <DownloadIcon
                t={t}
                text={t('exportAnnual')}
                onClick={handleAnnualReportDownload.mutateAsync}
                disabled={isAnnualDownloadDisabled}
              />
            </Col>
            <Col>
              <DownloadIcon
                t={t}
                text={t('exportMonthly')}
                onClick={handleMonthlyReportDownload.mutateAsync}
                disabled={isMonthlyDownloadDisabled}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <br />

      <TaxReportMonthlyTable
        t={t}
        showSearchInput={false}
        dataSource={taxReportData?.taxReport?.monthlyMileageTaxReportData || []}
      />

      <br />

      <Row gutter={16} wrap={false} justify="space-between" align="middle">
        <Col>
          <Text variant="h4">{t('annualMileageBasedYTD')}</Text>
        </Col>
        <Col>
          <DownloadIcon
            t={t}
            text={t('exportAnnual')}
            onClick={handleAnnualReportDownload.mutateAsync}
            disabled={isAnnualDownloadDisabled}
          />
        </Col>
      </Row>

      <br />

      <TaxReportAnnualTable
        t={t}
        showSearchInput={false}
        dataSource={taxReportData?.taxReport?.annualMileageTaxReportData || []}
      />
    </>
  );
};

export default UserTaxReportPageContent;
