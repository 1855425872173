import { get } from 'lodash';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { BrowserRouter, Route } from 'react-router-dom';

import useDebounce from '../../hooks/useDebounce';
import KliksLayout from '../../layout';
import { useStoreSelector } from '../../utils/storeSelectors';
import KliksAppViews from '../../views';
import AnalyticsToolPrivatePageTracker from '../AnalyticsToolPrivatePageTracker';
import ErrorBoundary from '../ErrorBoundary';
import SpaceSpinner from '../SpaceSpinner';
import useAuthenticatedViewsState from './useAuthenticatedViewsState';

const AuthenticatedViews = props => {
  const { location, ...rest } = props;

  const profile = useStoreSelector('profile');
  const { basename } = useStoreSelector('common');
  const { hasMounted } = useAuthenticatedViewsState({ location });
  const debouncedProfile = useDebounce(profile);
  const debouncedContainerKey = useDebounce(basename);

  if (
    !hasMounted ||
    !debouncedContainerKey ||
    debouncedContainerKey !== basename ||
    get(debouncedProfile, 'profile._id') !== get(profile, 'profile._id')
  ) {
    return <SpaceSpinner />;
  }

  return (
    <BrowserRouter key={basename} basename={basename}>
      <Route component={AnalyticsToolPrivatePageTracker} />

      <Route
        render={routeProps => {
          return (
            <React.Suspense fallback={<SpaceSpinner />}>
              <KliksLayout {...rest} {...routeProps}>
                <ErrorBoundary>
                  <KliksAppViews {...routeProps} />
                </ErrorBoundary>
              </KliksLayout>
            </React.Suspense>
          );
        }}
      />
    </BrowserRouter>
  );
};

export default withNamespaces()(AuthenticatedViews);
