import { AutoComplete } from 'antd';
import { filter, throttle, upperCase } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { CARS_API } from '../../api/cars';
import { getNumberRage } from '../../utils/common';
import TextInput from '../TextInput';

const VehicleYearAutoComplete = props => {
  const { t, disabled, onChange, value, ...rest } = props;

  const [internalValue, setInternalValue] = useState(value);

  const vehicleYearsQuery = useQuery(['fetchCarYears'], {
    staleTime: Infinity,
    queryFn: async () => {
      const carYears = await CARS_API.fetchCarYears();
      return getNumberRage(carYears.min_year, carYears.max_year).reverse();
    },
  });

  const vehicleMakeOptions = useMemo(() => {
    let options = [];

    if (Array.isArray(vehicleYearsQuery.data)) {
      options = [
        ...vehicleYearsQuery.data.map(make => ({
          label: make,
          value: make,
        })),
      ];
    }

    return internalValue
      ? filter(options, option => new RegExp(internalValue, 'gi').test(upperCase(option.value)))
      : options;
  }, [vehicleYearsQuery.data, internalValue]);

  const handleChange = useCallback(
    throttle(
      value => {
        if (typeof onChange === 'function') {
          onChange(value);
        }

        setInternalValue(value);
      },
      800,
      { trailing: true },
    ),
    [onChange],
  );

  return (
    <AutoComplete
      {...rest}
      value={value}
      options={vehicleMakeOptions}
      disabled={disabled || vehicleYearsQuery.isFetching}
      loading={vehicleYearsQuery.isFetching}
      onChange={handleChange}
    >
      <TextInput
        value={value}
        placeholder={t('selectYear')}
        disabled={disabled || vehicleYearsQuery.isFetching}
      />
    </AutoComplete>
  );
};

export default VehicleYearAutoComplete;
