import { Progress, Space, Statistic } from 'antd';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';

import { handleApiErrors } from '../../api/axiosInstance';
import { USER_API } from '../../api/user';
import Text from '../../components/Text';
import FadedText from '../../components/Text/FadedText';
import { formatDMLMonthYearPayload } from '../../utils/datetime';
import { formatNumberToLocale } from '../../utils/numbers';

const UserMileageCapCardContent = props => {
  const { t, userId, selectedDate } = props;

  const userMileageCapQuery = useQuery({
    staleTime: Infinity,
    cacheTime: 0,
    placeholderData: {
      currentMileageInPeriod: 0,
      mileageCap: 0,
      unit: 'mi',
    },
    queryKey: ['fetchUserMileageCap', formatDMLMonthYearPayload(selectedDate), userId],
    queryFn: () => USER_API.fetchUserMileageCap(userId, formatDMLMonthYearPayload(selectedDate)),
    onError: error => handleApiErrors(error.response),
  });

  const DMLCapProgressPercent = useMemo(() => {
    if (
      typeof userMileageCapQuery.data.currentMileageInPeriod !== 'number' ||
      typeof userMileageCapQuery.data.mileageCap !== 'number'
    )
      return 0;

    return (
      (userMileageCapQuery.data.currentMileageInPeriod * 100) / userMileageCapQuery.data.mileageCap
    );
  }, [userMileageCapQuery.data]);

  return (
    <div>
      <FadedText variant="b" renderAs="p" size="sm">
        {`${selectedDate.format('MMMM YYYY')}`}
      </FadedText>

      <Statistic
        loading={userMileageCapQuery.isFetching}
        valueRender={() => (
          <Space direction="vertical" wrap={false} size="small">
            <Text>
              {t('x_of_x_unit', {
                countA: formatNumberToLocale(userMileageCapQuery.data.currentMileageInPeriod),
                countB: formatNumberToLocale(userMileageCapQuery.data.mileageCap),
                unit: userMileageCapQuery.data.unit,
              })}
            </Text>

            <div>
              <Progress
                percent={DMLCapProgressPercent}
                strokeColor="#16b296"
                trailColor="#DADADA"
                strokeWidth={15}
                type="line"
                format={() => ''}
                style={{ display: 'block', marginRight: '-8px' }}
              />

              <FadedText textAlign="right">
                {t('remainingMileage_x_unit', {
                  count: formatNumberToLocale(
                    Math.max(
                      userMileageCapQuery.data.mileageCap -
                        userMileageCapQuery.data.currentMileageInPeriod,
                      0,
                    ),
                  ),
                  unit: userMileageCapQuery.data.unit,
                })}
              </FadedText>
            </div>
          </Space>
        )}
      />
    </div>
  );
};

export default UserMileageCapCardContent;
