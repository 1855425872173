import Spinner from 'components/Spinner';
import { INTERNAL_LINKS } from 'enum';
import { get } from 'lodash';
// import { createBrowserHistory } from 'history';
// import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import storage from 'store';

// import { saveCompany } from 'stores/actions/common';
// import { setRegStatus } from 'stores/actions/user';
import { COMPANY_API } from '../../api/company';
import { GROUPS_API } from '../../api/group';
import Toast from '../../components/Toast';
import useUserGroupsQuery from '../../hooks/queries/useUserGroupsQuery';
import useDebounce from '../../hooks/useDebounce';
import i18n from '../../i18n';
import { updateCompanyPlan } from '../../stores/actions/common';
import { updateGroupList } from '../../stores/actions/group';
import { PENDO_ANALYTICS } from '../../utils/pendoAnalytics';
import { hasCompanyAdminRole, hasCompanyManagerOrAdminRole, hasUserRole } from '../../utils/roles';
import { SENTRY_ANALYTICS } from '../../utils/sentry';
import { useStoreSelector } from '../../utils/storeSelectors';

export default function (WrappedComponent) {
  return ({ ...props }) => {
    const profile = useSelector(state => state.profile);
    const common = useSelector(state => state.common);
    const groupsList = useStoreSelector('group.groupList', []);
    const dispatch = useDispatch();
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const loadingProfile = useDebounce(loading);

    const companyId = useMemo(() => {
      return get(profile, 'profile.businessInfor.companies[0].companyID', '');
    }, [profile]);

    useEffect(() => {
      const hasAccessToken = storage.get('accessToken');

      if (!hasAccessToken || (loading && profile)) {
        setLoading(false);
      }

      if (hasAccessToken && !profile) {
        history.push(INTERNAL_LINKS.DASHBOARD);
      }

      return () => {
        setLoading(false);
      };
      // eslint-disable-next-line
    }, []);

    const activeSubscriptionQuery = useQuery({
      staleTime: Infinity,
      cacheTime: 0,
      enabled: !!companyId && hasCompanyAdminRole(profile) && !common?.companyData?.companyPlan,
      queryKey: ['authContainer', 'getActivePlan', companyId],
      queryFn: () => COMPANY_API.getActivePlan(companyId),
      onSuccess: plan => {
        dispatch(updateCompanyPlan(plan));
        PENDO_ANALYTICS.update(profile.profile, common.currentCompany, plan);
        SENTRY_ANALYTICS.update(profile.profile);
      },
      onError: () => {
        Toast({
          type: 'error',
          message: i18n.t('errorFetchingCompanyPLan'),
        });
      },
    });

    useUserGroupsQuery(profile?.profile?._id, {
      enabled: hasCompanyManagerOrAdminRole(profile) || hasUserRole(profile),
    });

    const groupsQuery = useQuery({
      staleTime: Infinity,
      cacheTime: 0,
      enabled:
        !!companyId &&
        !groupsList.length &&
        (hasCompanyManagerOrAdminRole(profile) || hasUserRole(profile)),
      queryKey: ['authContainer', 'fetchGroupList', companyId],
      queryFn: () => GROUPS_API.fetchGroupList(companyId),
      onSuccess: groupList => {
        dispatch(updateGroupList(groupList));
      },
      onError: () => {
        Toast({
          type: 'error',
          message: i18n.t('errorWhileFetchingCompanyGroups'),
        });
      },
    });

    if (loadingProfile || groupsQuery.isFetching || activeSubscriptionQuery.isFetching) {
      return (
        <Spinner spinning>
          <div style={{ height: '100vh' }} />
        </Spinner>
      );
    }

    return <WrappedComponent {...props} />;
  };
}
