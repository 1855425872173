import { Space } from 'antd';
import { get, isNull } from 'lodash';
import React, { useMemo } from 'react';

import useTableExpandedRows from '../../../hooks/useTableExpandedRows';
import { getUserFullName, momentFormat, momentTimezone } from '../../../utils/common';
import { CustomTable } from '../..';
import ComplianceItemsList from '../../ComplianceItemsList';
import StatusTag from '../../Tag/StatusTag';
import Text from '../../Text';

const ComplianceAuditLogTable = props => {
  const { t, dataSource, ...rest } = props;

  const { expandedRowKeys, toggleExpandedRow } = useTableExpandedRows();

  const COLUMNS = [
    {
      key: 'auditActivityName',
      title: t('auditActivityName'),
      dataIndex: 'reason',
      render: (reason, data) => (reason ? t(`${data.type}-${reason}`) : '-'),
    },
    {
      key: 'status',
      title: t('status'),
      dataIndex: 'status',
      render: status => <StatusTag status={status} />,
    },
    {
      key: 'dateTime',
      title: t('date/time'),
      dataIndex: 'created',
      render: created =>
        created
          ? `${momentFormat(created, 'DD MMM, YYYY')} (${momentTimezone(created).fromNow()})`
          : '-',
    },
    {
      key: 'user',
      title: t('user'),
      dataIndex: 'user',
      render: user => getUserFullName(user),
    },
    {
      key: 'overrideReason',
      title: t('overrideReason'),
      dataIndex: 'overrideReason',
      render: overrideReason => overrideReason || 'N/A',
    },
  ];

  const expandableDataSource = useMemo(
    () =>
      dataSource.map(record => {
        const complianceComponents = get(record, 'complianceComponents');
        return {
          ...record,
          expandable: !isNull(complianceComponents) && typeof complianceComponents === 'object',
        };
      }),
    [dataSource],
  );

  return (
    <CustomTable
      {...rest}
      columns={COLUMNS}
      dataSource={expandableDataSource}
      scroll={{ x: 1100 }}
      withExpandableRows
      expandedRowKeys={expandedRowKeys}
      onExpand={(expanded, record) => toggleExpandedRow(record)}
      expandedRowRender={data => {
        return (
          <Space direction="vertical">
            <Text variant="h5">{t(data.type)}</Text>

            <ComplianceItemsList
              t={t}
              type={data.type}
              complianceComponents={data.complianceComponents}
            />
          </Space>
        );
      }}
    />
  );
};

export default ComplianceAuditLogTable;
