import { Col, Row } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { INTERNAL_LINKS, STATUS_LIST } from '../../../enum';
import { momentFormat, momentTimezone } from '../../../utils/common';
import { getMomentDateWithoutTimezone } from '../../../utils/datetime';
import {
  sortColumnByDateString,
  sortColumnByMomentDate,
  sortColumnByStatus,
  sortColumnByStringField,
  sortColumnByUserFullName,
} from '../../../utils/tables';
import { CustomTable } from '../..';
import Button from '../../Button';
import ProfileNamePicture from '../../shared-ui/ProfileNamePicture';
import StatusTag from '../../Tag/StatusTag';
import LinkText from '../../Text/LinkText';
import FavrComplianceComponentsInfoTooltip from '../../Tooltips/FavrComplianceComponentsInfoTooltip';

const ComplianceWorklistTable = props => {
  const { t, dataSource, onDownload, onViewNotes, ...rest } = props;

  const [downloadingIds, setDownloadingIds] = useState([]);

  const handleDownload = async (id, status) => {
    setDownloadingIds(state => [...state, id]);
    try {
      await onDownload(id);
    } finally {
      setDownloadingIds(state => state.filter(insuranceId => insuranceId !== id));
    }
  };

  const COLUMNS = [
    {
      width: 180,
      title: t('status'),
      dataIndex: 'status',
      ...sortColumnByStatus('status'),
      render: (status, data) => (
        <Row align="middle" gutter={5} wrap={false}>
          <Col>
            <StatusTag status={status} />
          </Col>
          {[STATUS_LIST().Status.DENIED, STATUS_LIST().Status.NON_COMPLIANT].includes(status) && (
            <Col>
              <FavrComplianceComponentsInfoTooltip
                t={t}
                type={data.type}
                complianceComponents={data.complianceComponents}
              />
            </Col>
          )}
        </Row>
      ),
    },
    {
      width: 150,
      title: t('complianceType'),
      dataIndex: 'type',
      render: (type, data) =>
        ['insurance', 'business-vehicle'].includes(data.type) ? (
          <Link to={INTERNAL_LINKS.COMPLIANCE_WORKLIST_DETAILS(data._id)}>
            <LinkText variant="b">{t(type)}</LinkText>
          </Link>
        ) : (
          t(type)
        ),
      ...sortColumnByStringField('type'),
    },
    {
      width: 150,
      title: t('rate'),
      dataIndex: ['product', 'name'],
      ...sortColumnByStringField('product.name'),
    },
    {
      width: 200,
      title: t('name'),
      dataIndex: 'userId',
      render: user => (user ? <ProfileNamePicture showAvatar={false} user={user} /> : null),
      ...sortColumnByUserFullName('userId'),
    },
    {
      width: 200,
      ellipsis: true,
      title: t('Email'),
      dataIndex: ['userId', 'email'],
      ...sortColumnByStringField('userId.email'),
    },
    {
      width: 200,
      title: t('Company'),
      dataIndex: ['companyId', 'name'],
      ...sortColumnByStringField('companyId.name'),
    },
    {
      width: 180,
      title: t('compliancePeriod'),
      dataIndex: 'compliancePeriod',
      ...sortColumnByDateString('compliancePeriod.startDate'),
      render: period =>
        period?.startDate && period?.endDate
          ? [
              momentFormat(period.startDate, 'MMM YYYY'),
              momentFormat(period.endDate, 'MMM YYYY'),
            ].join(' - ')
          : '-',
    },
    {
      width: 180,
      title: t('coveragePeriod'),
      dataIndex: 'coveragePeriod',
      ...sortColumnByMomentDate('coveragePeriod.startDate'),
      render: period =>
        period?.startDate && period?.endDate
          ? [
              getMomentDateWithoutTimezone(period.startDate).format('MMM YYYY'),
              getMomentDateWithoutTimezone(period.endDate).format('MMM YYYY'),
            ].join(' - ')
          : '-',
    },
    {
      width: 150,
      key: 'gracePeriod',
      title: t('gracePeriodEndDate'),
      dataIndex: 'endOfGracePeriodDate',
      render: endOfGracePeriodDate =>
        endOfGracePeriodDate
          ? `${momentFormat(endOfGracePeriodDate, 'DD MMM, YYYY')} (${momentTimezone(
              endOfGracePeriodDate,
            ).fromNow()})`
          : '-',
    },
    {
      width: 150,
      title: t('submitted'),
      dataIndex: 'submittedDate',
      render: date => momentFormat(date, 'MMMM DD, YYYY'),
      ...sortColumnByDateString('submittedDate'),
    },
    {
      width: 135,
      title: '',
      render: data => (
        <Button
          block
          size="xs"
          disabled={!data.filesUploaded || downloadingIds.includes(data._id)}
          loading={downloadingIds.includes(data._id)}
          onClick={() => handleDownload(data._id, data.status)}
        >
          {t('download')}
        </Button>
      ),
    },
    {
      width: 150,
      title: t('commentsForAdmin'),
      render: data => (
        <Row gutter={16} wrap={false}>
          <Col>
            <LinkText variant="b" onClick={() => onViewNotes(data)}>
              {t('view')}
            </LinkText>
          </Col>
          <Col>
            <LinkText variant="b" onClick={() => onViewNotes(data)}>
              {t('add')}
            </LinkText>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <CustomTable
      {...rest}
      columns={COLUMNS}
      showSearchInput={false}
      dataSource={dataSource}
      scroll={{ x: 900 }}
    />
  );
};

export default ComplianceWorklistTable;
