import { Collapse } from 'antd';
import { isNull } from 'lodash';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { IMAGES, INTERNAL_LINKS, STATUS_LIST } from '../../../enum';
import {
  checkIfStringContainsValue,
  getUserFullName,
  momentFormat,
  momentTimezone,
} from '../../../utils/common';
import { formatCurrencyToCompanyLocale } from '../../../utils/numbers';
import { scrollAppContainerToTop } from '../../../utils/window';
import { CustomTable } from '../..';
import ComplianceItemsList from '../../ComplianceItemsList';
import ProfileNamePicture from '../../shared-ui/ProfileNamePicture';
import StatusTag from '../../Tag/StatusTag';
import AmountTextReverseColors from '../../Text/AmountTextReverseColors';

const complianceDetailsKeysMap = {
  businessMileageCompliance: 'business-mileage',
  businessVehicleCompliance: 'business-vehicle',
  insuranceCompliance: 'insurance',
};

const ComplianceStatusTable = props => {
  const { t, dataSource, searchTerm, ...rest } = props;

  const filteredDataSource = useMemo(() => {
    let array = [...dataSource];

    if (searchTerm) {
      array = array.filter(item => {
        return checkIfStringContainsValue(getUserFullName(item), searchTerm);
      });
    }

    return array;
  }, [dataSource, searchTerm]);

  const COLUMNS = [
    {
      defaultSortOrder: 'ascend',
      key: ['firstName', 'lastName'].join(','),
      title: t('name'),
      render: (_, data) => (
        <ProfileNamePicture user={data} redirectTo={INTERNAL_LINKS.COMPLIANCE_DETAILS(data._id)} />
      ),
      sorter: true,
    },
    {
      width: 150,
      sorter: true,
      key: 'startDate',
      title: t('startDate'),
      dataIndex: 'startDate',
      render: startDate => (startDate ? momentFormat(startDate, 'DD MMM, YYYY') : '-'),
    },
    {
      sorter: true,
      key: 'complianceStatus',
      title: t('complianceStatus'),
      dataIndex: 'complianceStatus',
      render: (status, data) => <StatusTag status={status} />,
    },
    {
      width: 400,
      key: 'complianceDetails',
      title: t('nonComplianceDetails'),
      dataIndex: 'complianceDetails',
      render: complianceDetails => {
        if (complianceDetails) {
          const invalidDocuments = Object.keys(complianceDetails).filter(key => {
            const compliance = complianceDetails[key];
            return compliance.status === STATUS_LIST().Status.INVALID;
          });

          if (!invalidDocuments.length) return '-';

          return invalidDocuments.map(key => {
            const compliance = complianceDetails[key];
            return (
              <Collapse
                ghost
                expandIcon={({ isActive }) => (
                  <img
                    src={IMAGES.GREEN_CHEVRON_DOWN_ICON}
                    alt="expand icon"
                    style={{ transform: `translateY(-3px) rotate(${isActive ? 0 : 180}deg)` }}
                  />
                )}
              >
                <Collapse.Panel header={t(complianceDetailsKeysMap[key])} key={key}>
                  <ComplianceItemsList
                    t={t}
                    type={complianceDetailsKeysMap[key]}
                    complianceComponents={compliance.complianceComponents || compliance}
                  />
                </Collapse.Panel>
              </Collapse>
            );
          });
        }

        return '-';
      },
    },
    {
      width: 150,
      sorter: true,
      key: 'endOfGracePeriodDate',
      title: t('gracePeriodEndDate'),
      dataIndex: 'endOfGracePeriodDate',
      render: endOfGracePeriodDate =>
        endOfGracePeriodDate ? (
          <>
            <p>{`${momentFormat(endOfGracePeriodDate, 'DD MMM, YYYY')}`}</p>
            <p>{`(${momentTimezone(endOfGracePeriodDate).fromNow()})`}</p>
          </>
        ) : (
          '-'
        ),
    },
    {
      sorter: true,
      key: 'lastAction.reason',
      title: t('lastAction'),
      dataIndex: ['lastAction', 'reason'],
      render: (reason, data) => (reason ? t(`${data.lastAction.type}-${reason}`) : '-'),
    },
    {
      sorter: true,
      key: 'lastAction.created',
      title: t('lastActionDate'),
      dataIndex: ['lastAction', 'created'],
      render: created =>
        created
          ? `${momentFormat(created, 'DD MMM, YYYY')} (${momentTimezone(created).fromNow()})`
          : '-',
    },
    {
      sorter: true,
      key: 'taxDue',
      title: t('taxDue'),
      dataIndex: 'taxDue',
      render: (taxDue, data) =>
        isNull(taxDue) ? (
          '-'
        ) : (
          <Link to={INTERNAL_LINKS.USER_TAX_REPORT(data._id)} onClick={scrollAppContainerToTop}>
            <AmountTextReverseColors variant="b" amount={formatCurrencyToCompanyLocale(taxDue)} />
          </Link>
        ),
    },
  ];

  return (
    <CustomTable
      {...rest}
      columns={COLUMNS}
      showSearchInput={false}
      dataSource={filteredDataSource}
      scroll={{ x: 1400 }}
    />
  );
};

export default ComplianceStatusTable;
