import * as Sentry from '@sentry/react';
import { get } from 'lodash';

import { removeFalseyParams } from './queryParams';
import { hasBackOfficeUserRole, hasSystemAdminRole } from './roles';
import { getEnvironmentName } from './window';

const appendEnvironment = string => {
  return `${getEnvironmentName()}_${string}`;
};

const formatPendoAccountData = (userData = {}) => {
  const isSystemAdmin = hasSystemAdminRole({ profile: userData });
  const isBackOfficeUser = hasBackOfficeUserRole({ profile: userData });

  if (isSystemAdmin) {
    return {
      id: appendEnvironment('kliks-system'),
      username: 'Kliks System',
    };
  } else if (isBackOfficeUser) {
    return {
      id: appendEnvironment('back-office'),
      username: 'Kliks Back Office',
    };
  } else if (userData) {
    return removeFalseyParams({
      id: appendEnvironment(get(userData, '_id', 'ACCOUNT-UNIQUE-ID')),
      username: get(userData, 'email'),
      email: get(userData, 'email'),
    });
  } else {
    return null;
  }
};

export const SENTRY_ANALYTICS = Object.freeze({
  initialize: () => {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.NODE_ENV,
      debug: process.env.NODE_ENV === 'development',
      enabled: process.env.NODE_ENV !== 'development',
      release: process.env.REACT_APP_VERSION,

      // This error can be safely ignored
      ignoreErrors: [
        /ResizeObserver/gi,
        /AxiosError/gi,
        /Request failed with status/gi,
        /ChunkLoad/gi,
      ],

      // This sets the sample rate to be 10% in production and 100% in development.
      replaysSessionSampleRate: 0,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 0,
      integrations: [
        Sentry.replayIntegration({
          // Additional SDK configuration goes in here, for example:
          // https://docs.sentry.io/platforms/javascript/session-replay/configuration
          maskAllText: true,
          blockAllMedia: true,
          networkDetailAllowUrls: [process.env.REACT_APP_HOST_API],
          unmask: ['[data-sentry-unmask]'],
        }),
      ],
    });

    window.sentryInit = true;
  },
  /**
   * Update Visitor and Account data
   * @param {*} userData
   */
  update: (userData = {}) => {
    if (window.sentryInit) {
      Sentry.setUser(formatPendoAccountData(userData));
    }
  },
  /**
   * Starts a new session replay recording if Sentry is initialized
   * Used to capture user interactions and reproduce issues
   */
  startReplay: () => {
    if (window.sentryInit) {
      const replay = Sentry.getReplay();
      replay.start();
    }
  },

  /**
   * Stops the current session replay recording if Sentry is initialized
   * Should be called when unmounting components to prevent memory leaks
   * @returns {Promise<void>} Promise that resolves when replay is stopped
   */
  stopReplay: async () => {
    if (window.sentryInit) {
      const replay = Sentry.getReplay();
      await replay.stop();
    }
  },
});
