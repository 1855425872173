import React, { useMemo } from 'react';
import { useQuery } from 'react-query';

import { CARS_API } from '../../api/cars';
import Select from '.';

const VehicleModelSelect = props => {
  const { t, year, make, disabled, showOtherOption, ...rest } = props;

  const vehicleModelsQuery = useQuery(['fetchCarModels', year, make], {
    enabled: !!year && !!make && make !== 'other',
    staleTime: Infinity,
    queryFn: () => CARS_API.fetchCarModels(year, make),
  });

  const vehicleModelOptions = useMemo(() => {
    let options = [];

    if (Array.isArray(vehicleModelsQuery.data)) {
      return [...vehicleModelsQuery.data.map(model => ({ label: model, value: model }))];
    }

    if (showOtherOption) {
      options.push({ label: 'Other', value: 'other' });
    }

    return options;
  }, [vehicleModelsQuery.data, showOtherOption]);

  return (
    <Select
      {...rest}
      showSearch
      disabled={
        disabled ||
        !make ||
        !year ||
        make === 'other' ||
        vehicleModelsQuery.isFetching ||
        !vehicleModelOptions?.length
      }
      loading={vehicleModelsQuery.isFetching}
      placeholder={t('selectModel')}
      options={vehicleModelOptions}
    />
  );
};

VehicleModelSelect.defaultProps = {
  showOtherOption: true,
};

export default VehicleModelSelect;
