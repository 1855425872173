import './style.scss';

import { Form, Layout, Row } from 'antd';
import { signInUser } from 'api/auth';
import FullLogo from 'components/FullLogo';
import Spinner from 'components/Spinner';
import AuthContainer from 'containers/Auth';
import { kebabCase } from 'lodash';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setRegStatus } from 'stores/actions/user';

import { handleApiErrors } from '../../../api/axiosInstance';
import ErrorAlert from '../../../components/Alert/ErrorAlert';
import WarningAlert from '../../../components/Alert/WarningAlert';
import Box from '../../../components/Box';
import Button from '../../../components/Button';
import TextInput from '../../../components/TextInput';
import { INTERNAL_LINKS, USER_REG_STATUS } from '../../../enum';
import { BACK_OFFICE_BASENAME, KLIKS_SYSTEM_BASENAME } from '../../../enum/InternalLinks';
import useLocationSearchQueryParser from '../../../hooks/useLocationSearchQueryParser';
import i18n from '../../../i18n';
import { emitUpdateGlobalBasename, saveCompany } from '../../../stores/actions/common';
import { updateProfile } from '../../../stores/actions/profile';
import { emitNavigateTo } from '../../../stores/actions/router';
import { formatPageTitle } from '../../../utils/common';
import { NEW_PASSWORD_FORM_RULE } from '../../../utils/constants';
import { setAnalyticsUserProperties } from '../../../utils/googleAnalytics';
import { PENDO_ANALYTICS } from '../../../utils/pendoAnalytics';
import { hasBackOfficeUserRole, hasSystemAdminRole } from '../../../utils/roles';
import { SENTRY_ANALYTICS } from '../../../utils/sentry';
import { normalizeUserSchema } from '../../../utils/users';

const { Content } = Layout;

const NewLoginForm = ({ t, location }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [displayLoginWarning, setDisplayLoginWarning] = useState(false);
  const [accountLockedMessage, setAccountLockedMessage] = useState();
  const { redirect_uri } = useLocationSearchQueryParser(location);

  const handleLogin = async formValue => {
    setLoading(true);
    try {
      let loginResult = await signInUser(formValue.email, formValue.password);
      if (loginResult.user) {
        const language = loginResult.user?.preferences?.language || 'en';
        i18n.changeLanguage(language);
      }

      if (loginResult?.nextAttemptLock) {
        setAccountLockedMessage(false);
        setDisplayLoginWarning(true);
      } else if (loginResult?.accountLocked) {
        setDisplayLoginWarning(false);
        setAccountLockedMessage(loginResult.message);
      } else {
        setDisplayLoginWarning(false);
        setAccountLockedMessage(false);
      }

      if (
        loginResult.user &&
        !hasSystemAdminRole({ profile: loginResult.user }) &&
        !hasBackOfficeUserRole({ profile: loginResult.user })
      ) {
        setAnalyticsUserProperties(loginResult.user);
        PENDO_ANALYTICS.update(loginResult.user, loginResult.company);
        SENTRY_ANALYTICS.update(loginResult.user);
      }

      if (loginResult.user && hasSystemAdminRole({ profile: loginResult.user })) {
        dispatch(emitUpdateGlobalBasename(KLIKS_SYSTEM_BASENAME));
        return dispatch(emitNavigateTo(INTERNAL_LINKS.SELECT_COMPANY));
      }

      if (loginResult.user && hasBackOfficeUserRole({ profile: loginResult.user })) {
        dispatch(emitUpdateGlobalBasename(BACK_OFFICE_BASENAME));
        return dispatch(emitNavigateTo(INTERNAL_LINKS.BACK_OFFICE_HOME));
      }

      let normalizedUser;
      if (loginResult.user) {
        normalizedUser = normalizeUserSchema(loginResult.user);
        await dispatch(updateProfile(normalizedUser));
      }

      if (loginResult.company.regStatus) {
        await dispatch(setRegStatus(loginResult.company.regStatus));
        await dispatch(saveCompany(loginResult.company));
        dispatch(emitUpdateGlobalBasename(kebabCase(loginResult.company.name)));

        if (!loginResult.company.regCompleted) {
          dispatch(emitNavigateTo(INTERNAL_LINKS.COMPANY_ONBOARDING));
        } else {
          dispatch(emitNavigateTo(redirect_uri || INTERNAL_LINKS.DASHBOARD, !!redirect_uri));
        }
      } else if (loginResult.company.regCompleted) {
        dispatch(emitNavigateTo(redirect_uri || INTERNAL_LINKS.DASHBOARD, !!redirect_uri));
      } else {
        await dispatch(setRegStatus(USER_REG_STATUS.COMPANY));
        dispatch(emitNavigateTo(INTERNAL_LINKS.COMPANY_ONBOARDING));
      }
    } catch (e) {
      handleApiErrors(e.response);
    }
    setLoading(false);
  };

  return (
    <Spinner spinning={loading}>
      <Helmet>
        <title>{formatPageTitle('Login')}</title>
      </Helmet>

      <Layout className="kl-login-form" style={{ minHeight: '100vh' }}>
        <div className="logo">
          <FullLogo />
        </div>
        <Box hoverable={false} padding={50} className="content-wrapper">
          <Content>
            <Row className="kliks-row">
              <div className="kliks-tagline">{t('loginToYourAccount')}</div>
            </Row>

            {displayLoginWarning && <WarningAlert message={t('yourAccountWillBeLocked')} />}
            {!!accountLockedMessage && (
              <ErrorAlert
                title={t('accountLocked')}
                message={
                  <>
                    {accountLockedMessage}
                    <Link to={INTERNAL_LINKS.FORGOT_PASSWORD}>{t('forgotPassword')}</Link>
                    {'.'}
                  </>
                }
              />
            )}

            <Form name="login" onFinish={handleLogin} initialValues={{ remember: true }}>
              <Row>
                <Form.Item
                  className="kliks-row reg-forms"
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: t('enterValidEmail'),
                    },
                  ]}
                >
                  <TextInput
                    size="large"
                    placeholder={t('enterValidWorkEmail')}
                    autoComplete="username"
                  />
                </Form.Item>
              </Row>

              <Row>
                <Form.Item
                  className="kliks-row reg-forms"
                  name="password"
                  rules={NEW_PASSWORD_FORM_RULE()}
                >
                  <TextInput
                    password
                    size="large"
                    placeholder={t('Password')}
                    autoComplete="current-password"
                  />
                </Form.Item>
                <div className="forgot-password">
                  <Link to={INTERNAL_LINKS.FORGOT_PASSWORD}>{t('forgotPassword?')}</Link>
                </div>
              </Row>

              <Row>
                <Form.Item className="kliks-row reg-forms button">
                  <Button
                    block
                    type="primary"
                    size="medium"
                    className="start-trial-btn primary"
                    htmlType="submit"
                  >
                    {t('logIn')}
                  </Button>
                </Form.Item>
              </Row>

              <Row className="kliks-row">
                <div className="to-signin">
                  {t(`Don't have an account?`)}{' '}
                  <Link to={INTERNAL_LINKS.SIGNUP} style={{ whiteSpace: 'nowrap' }}>
                    {t('signUp!')}
                  </Link>
                </div>
              </Row>
            </Form>
          </Content>
        </Box>
      </Layout>
    </Spinner>
  );
};

export default AuthContainer(withNamespaces()(NewLoginForm));
